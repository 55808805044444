import { ProductListFormErrors, ProductListFormValues } from '../../../types'

const validation = (values: ProductListFormValues): [boolean, ProductListFormErrors] => {
  const errors: ProductListFormErrors = {}

  if (!values.name) errors.name = 'Name required'

  return [Object.keys(errors).length === 0, errors]
}

export default validation
