import Container from 'react-bootstrap/Container'
import PageHeader from './PageHeader'
import React from 'react'

type Props = {
  children?: React.ReactNode
  className?: string
  subTitle?: string
  title?: string
}

const PageContent: React.FC<Props> = ({ children, className, title, subTitle }) => (
  <div className={`page-content ${className}`}>
    <Container>
      {(title || subTitle) && <PageHeader title={title} subTitle={subTitle} />}
      {children}
    </Container>
  </div>
)

PageContent.displayName = 'Page.Content'

export default PageContent
