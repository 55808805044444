import { SocialMedia } from '../../types'
import axios from 'axios'

const API = process.env.REACT_APP_API_ENDPOINT

export const updateSocialMedia = async (socialMedia: SocialMedia): Promise<SocialMedia | false> => {
  try {
    const { data } = await axios.put<{ socialMedia: SocialMedia }>(`${API}/api/v1/user/socialMedia`, { socialMedia })

    return data.socialMedia
  } catch (error) {
    return false
  }
}
