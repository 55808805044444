import { Link, useLocation } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import { useSelector } from '../../../hooks'
import { Role } from '../../../enums'
import React from 'react'

type Props = {
  setActiveKey: (key: string) => void
}

const NavLinks: React.FC<Props> = ({ setActiveKey }) => {
  const role = useSelector(state => state.auth.user?.role)

  const userLinks = (
    <>
      <Nav.Item>
        <Nav.Link
          as={Link}
          active={useLocation().pathname === '/' ? true : false}
          onClick={() => setActiveKey('0')}
          to='/'
        >
          <i className='fe fe-list' />
          Events
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          active={useLocation().pathname === '/products' ? true : false}
          onClick={() => setActiveKey('0')}
          to='/products'
        >
          <i className='fe fe-list' />
          Products
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          active={useLocation().pathname === '/settings' ? true : false}
          onClick={() => setActiveKey('0')}
          to='/settings'
        >
          <i className='fe fe-settings' />
          Settings
        </Nav.Link>
      </Nav.Item>
    </>
  )

  const adminLinks = (
    <>
      <Nav.Item>
        <Nav.Link
          as={Link}
          active={useLocation().pathname === '/' ? true : false}
          onClick={() => setActiveKey('0')}
          to='/'
        >
          <i className='fe fe-home' />
          Home
        </Nav.Link>
      </Nav.Item>
    </>
  )
  return role === Role.admin ? adminLinks : role === Role.user ? userLinks : null
}

export default NavLinks
