import { Container, Image, Accordion } from 'react-bootstrap'
import DropdownMenu from './DropdownMenu'
import Logo from '../../../assets/images/linkzyLogo.png'
import React from 'react'

type Props = {
  activeKey: string
  setActiveKey: (key: string) => void
}

const Header: React.FC<Props> = ({ activeKey, setActiveKey }) => {
  return (
    <div className='header py-4'>
      <Container className='d-flex'>
        <a href='/' className='header-brand'>
          <Image src={Logo} className='header-brand-img' />
        </a>
        <div className='d-flex order-lg-2 ml-auto'>
          <DropdownMenu />
        </div>

        <Accordion.Toggle
          className='header-toggler d-lg-none ml-3 ml-lg-0'
          as='a'
          eventKey=''
          onClick={() => setActiveKey(activeKey === '0' ? '4' : '0')}
        >
          <span className='header-toggler-icon' />
        </Accordion.Toggle>
      </Container>
    </div>
  )
}

export default Header
