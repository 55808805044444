import { EventFormValues, NewEvent } from '../types'
import { PaymentSolution, StreamingSolution } from '../enums'
import { getTime, setHours, setMinutes, subMinutes, toDate } from 'date-fns'
import API from '../api'

export const newEvent = async ({
  date,
  description,
  hour,
  minute,
  name,
  openingTime,
  paymentSolution,
  registrationsStatus,
  status,
  streamingLinkPassword,
  streamingSolution,
  stripePrice,
  stripeProduct,
  zoomLink,
  zoomMeetingId
}: EventFormValues): Promise<NewEvent | null> => {
  const event: NewEvent = {
    date: getTime(toDate(getTime(setHours(setMinutes(date, minute), hour)))),
    description: description || undefined,
    name,
    paymentSolution: paymentSolution || undefined,
    openingTime: openingTime
      ? getTime(subMinutes(toDate(getTime(setHours(setMinutes(date, minute), hour))), openingTime))
      : undefined,
    registrationsStatus: paymentSolution ? registrationsStatus : undefined,
    status,
    streamingLinkPassword: [StreamingSolution.link, StreamingSolution.protectedLink].includes(streamingSolution)
      ? streamingLinkPassword
      : undefined,
    streamingSolution,
    stripePrice: paymentSolution === PaymentSolution.stripe && stripePrice ? stripePrice : undefined,
    stripeProduct: paymentSolution === PaymentSolution.stripe && stripeProduct ? stripeProduct : undefined,
    zoomLink: [StreamingSolution.link, StreamingSolution.protectedLink].includes(streamingSolution)
      ? zoomLink
      : undefined
  }

  if (streamingSolution === StreamingSolution.zoom && zoomMeetingId) {
    const zoomMeeting = await API.Zoom.getMeeting(zoomMeetingId)
    if (!zoomMeeting) return null

    event.zoomMeeting = zoomMeeting
  }

  return event
}
