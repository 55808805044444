import { EventList, EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const addEventList = (
  newEventList: EventList
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => async dispatch => {
  dispatch({
    type: EventListsActionTypes.ADD_EVENT_LIST,
    payload: newEventList
  })
}
