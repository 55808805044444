import { IGlobalState } from '../types'

const initialState: IGlobalState = {
  auth: {
    error: null,
    expiresAt: localStorage.getItem('expiresAt') ? Number(localStorage.getItem('expiresAt')) : null,
    isAuthenticated: false,
    loading: false,
    refreshToken: localStorage.getItem('refreshToken'),
    token: localStorage.getItem('token'),
    user: null,
    //
    actionCodeValid: undefined,
    passwordReseted: undefined,
    resetPasswordEmailSent: undefined
  },
  eventLists: {
    error: null,
    eventLists: [],
    loading: false,
    selectedEvent: null,
    selectedEventListIndex: 0
  },
  modals: {
    modalProps: undefined,
    modalType: null,
    show: true
  },
  productLists: {
    error: null,
    productLists: [],
    loading: false,
    selectedProduct: null,
    selectedProductListIndex: 0
  }
}

export default initialState
