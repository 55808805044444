import { Container, Row, Col, Nav, AccordionCollapse } from 'react-bootstrap'
import NavLinks from './NavLinks'
import React from 'react'

type Props = {
  setActiveKey: (key: string) => void
}

const Navbar: React.FC<Props> = ({ setActiveKey }) => {
  const Bar = () => (
    <Container>
      <Row className='align-items-center'>
        <Col className='col col-lg order-lg-first'>
          <Nav variant='tabs' defaultActiveKey='home' className='border-0 flex-column flex-lg-row'>
            <NavLinks setActiveKey={setActiveKey} />
          </Nav>
        </Col>
      </Row>
    </Container>
  )

  return (
    <>
      <div className='header d-lg-flex p-0 collapse'>
        <Bar />
      </div>
      <AccordionCollapse eventKey='4'>
        <div className='header d-lg-none p-0 collapsed'>
          <Bar />
        </div>
      </AccordionCollapse>
    </>
  )
}

export default Navbar
