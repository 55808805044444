import { Button } from 'tabler-react'
import { Card, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import React from 'react'

type Props = {
  title: string
  buttonText: string
  onSubmit: () => void
  loading: boolean | string
  success?: boolean
  successText?: string
  error?: string
  dual?: boolean
  stacked?: boolean
  prevText?: string
  prevIcon?: string
  prevAction?: () => void
  children: React.ReactNode
}
const FormCard: React.FC<Props> = ({
  title,
  buttonText,
  onSubmit,
  loading,
  success,
  successText,
  error,
  dual,
  stacked,
  prevText,
  prevIcon,
  prevAction,
  children
}) => {
  const history = useHistory()

  const singleButton = success ? (
    <Button icon='check' color='success' disabled block={true}>
      {successText}
    </Button>
  ) : (
    <Button
      disabled={loading || error}
      loading={loading}
      type='submit'
      color={error ? 'danger' : 'primary'}
      block={true}
      onClick={() => onSubmit()}
    >
      {buttonText}
    </Button>
  )

  const twoButtons = (
    <>
      {prevText ||
        (prevIcon && (
          <Button type='button' color='grey' icon={prevIcon} onClick={prevAction}>
            {prevText}
          </Button>
        ))}
      <Button
        className='ml-auto'
        disabled={loading || error}
        loading={loading}
        type='button'
        color={error ? 'danger' : 'primary'}
        onClick={() => onSubmit()}
      >
        {buttonText}
      </Button>
    </>
  )

  const stackedButtons = (
    <Button.List className='mt-6'>
      <Button
        // className='mb-3'
        block
        disabled={loading || error}
        loading={loading}
        type='button'
        color={error ? 'danger' : 'primary'}
        onClick={() => onSubmit()}
      >
        {buttonText}
      </Button>
      <Button block type='button' color='primary' onClick={() => history.push('/signup')}>
        Sign up
      </Button>
    </Button.List>
  )

  return (
    <Form noValidate className='card' onSubmit={onSubmit}>
      <Card.Body className='p-6'>
        <Card.Title>{title}</Card.Title>
        {children}
        {stacked ? (
          stackedButtons
        ) : (
          <div className={'form-footer'} style={{ display: 'flex' }}>
            {dual ? twoButtons : singleButton}
          </div>
        )}
      </Card.Body>
    </Form>
  )
}

export default FormCard
