import { ActionCodeMode } from '../../../enums'
import { Loader } from 'tabler-react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import { verifyActionCode } from '../../../redux/actions/authActions'
import ErrorPage from '../../errorPage'
import React, { useEffect, useState } from 'react'
import ResetPassword from './ResetPassword'
import Row from 'react-bootstrap/Row'

const EmailActionsHandler: React.FC<RouteComponentProps> = ({ history }) => {
  const { actionCodeValid, isAuthenticated, error } = useSelector(state => state.auth)
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch()

  const params = new URLSearchParams(useLocation().search)
  const mode = params.get('mode') as ActionCodeMode
  const actionCode = params.get('oobCode')

  useEffect(() => {
    mode && actionCode && dispatch(verifyActionCode(mode, actionCode))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isAuthenticated || mode === null || actionCode === null) {
      history.push('/')
    }
    // eslint-disable-next-line
  }, [redirect])

  const ui = () => {
    switch (mode) {
      case ActionCodeMode.resetPassword:
        return actionCode ? <ResetPassword actionCode={actionCode} setRedirect={setRedirect} /> : null
      default:
        return null
    }
  }

  return error === 'Code invalid' ? (
    <ErrorPage
      title='400'
      subtitle='Oops... You just found an error page...'
      details='This link is either invalid or has expired'
      buttonText='Home'
      buttonAction={() => (window.location.href = '/')}
    />
  ) : !actionCodeValid ? (
    <Row className='h-100 justify-content-center align-items-center'>
      <Loader />
    </Row>
  ) : (
    ui()
  )
}

export default EmailActionsHandler
