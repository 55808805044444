import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import axiosConfig from './utils/axiosConfig'
import Dashboard from './pages/Dashboard'
import EmailActionHandler from './pages/auth/emailActions'
import EventsList from './pages/eventsList'
import ForgotPassword from './pages/auth/ForgotPassword'
import Login from './pages/auth/Login'
import ModalRoot from './components/modals/ModalRoot'
import PaymentSuccess from './pages/redirects/paymentSuccess'
import PrivateRoute from './components/routing/PrivateRoute'
import ProductList from './pages/productList'
import React from 'react'
import SignUp from './pages/auth/SignUp'
import store from './redux/store'

import 'tabler-react/dist/Tabler.css'
import './App.css'

axiosConfig()

const App: React.FC = () => (
  <Provider store={store}>
    <ModalRoot />
    <Router>
      <Switch>
        <PrivateRoute exact path='/' component={Dashboard} />
        <Route exact path='/events/:id' component={EventsList} />
        <Route exact path='/products/:id' component={ProductList} />
        <Route exact path='/paymentSuccess' component={PaymentSuccess} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/signup' component={SignUp} />
        <Route exact path='/forgotpassword' component={ForgotPassword} />
        <Route exact path='/emailactions' component={EmailActionHandler} />
        <PrivateRoute path='/:page' component={Dashboard} />
      </Switch>
    </Router>
  </Provider>
)

export default App
