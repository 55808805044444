import * as yup from 'yup'

export const emailValidation = (values: { email: string }): { email?: string } => {
  const errors: { email?: string } = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  return errors
}

export const passwordValidation = (values: { password: string }): { password?: string } => {
  const errors: { password?: string } = {}

  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < 16) {
    errors.password = 'Password must be at least 16 characters long'
  }

  return errors
}

export const validation = (values: { email: string; password: string }): { email?: string; password?: string } => ({
  ...emailValidation(values),
  ...passwordValidation(values)
})

export const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(16).required()
})

export const signUpSchema = yup.object({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(16).required()
})
