import { Avatar } from 'tabler-react'
import { Dropdown } from 'react-bootstrap'
import { logout } from '../../../redux/actions/authActions'
import { Role } from '../../../enums'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import React, { forwardRef } from 'react'

const DropdownMenu: React.FC = () => {
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const CustomToggle: React.ForwardRefExoticComponent<
    React.RefAttributes<HTMLAnchorElement> & { onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void }
  > = React.forwardRef(({ children, onClick }, ref) =>
    user ? (
      <a
        href=' '
        ref={ref}
        className='nav-link pr-0 leading-none'
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        <Avatar color='blue'>{user.firstname.charAt(0) + user.lastname.charAt(0)}</Avatar>
        <span className='ml-2 d-none d-lg-block'>
          <span className='text-default'>{`${user.firstname} ${user.lastname}`}</span>
          <small className='text-muted d-block mt-1'>
            {user.role === Role.admin ? 'Administrator' : 'User account'}
          </small>
        </span>
        {children}
      </a>
    ) : null
  )

  CustomToggle.displayName = 'CustomToggle'

  type CustomMenuProps = {
    children: React.ReactNode
    className: string
    style?: Record<string, string>
    'aria-labelledby'?: string
  }

  const CustomMenu: React.ForwardRefExoticComponent<React.RefAttributes<HTMLDivElement> & CustomMenuProps> = forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          {children}
        </div>
      )
    }
  )

  CustomMenu.displayName = 'CustomMenu'

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'></Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        alignRight={true}
        className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
      >
        <Dropdown.Item as='a' onClick={() => dispatch(logout())}>
          <i className='fe fe-log-out dropdown-icon' /> Sign out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownMenu
