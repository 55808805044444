import { AuthAction, AuthActionsTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'
import setLoading from './setLoading'

export const login = (
  email: string,
  password: string
): ThunkAction<void, IGlobalState, unknown, AuthAction> => async dispatch => {
  dispatch(setLoading('login'))

  const tokens = await API.Auth.login(email, password)

  if (tokens) {
    dispatch({
      type: AuthActionsTypes.LOGIN_SUCCESS,
      payload: tokens
    })
  } else {
    dispatch({ type: AuthActionsTypes.LOGIN_FAIL, payload: 'login error' })

    setTimeout(() => dispatch({ type: AuthActionsTypes.CLEAR_ERRORS }), 5000)
  }
}
