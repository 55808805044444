import { AuthAction, AuthActionsTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'
import setLoading from './setLoading'

export const signUp = (formData: {
  firstname: string
  lastname: string
  email: string
  password: string
}): ThunkAction<void, IGlobalState, unknown, AuthAction> => async dispatch => {
  dispatch(setLoading('signUp'))

  const tokens = await API.Auth.signUp(formData)

  if (tokens) {
    dispatch({
      type: AuthActionsTypes.SIGN_UP_SUCCESSS,
      payload: tokens
    })
  } else {
    dispatch({
      type: AuthActionsTypes.SIGN_UP_FAIL,
      payload: 'signUp error'
    })

    setTimeout(() => dispatch({ type: AuthActionsTypes.CLEAR_ERRORS }), 5000)
  }
}
