export enum EventStatus {
  closed = 'closed',
  open = 'open'
}

export enum PaymentSolution {
  stripe = 'stripe'
}

export enum RegistrationsStatus {
  closed = 'closed',
  open = 'open'
}

export enum StreamingSolution {
  link = 'link',
  protectedLink = 'protectedLink',
  zoom = 'zoom'
}
