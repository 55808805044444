import { Col, Container, Image, Row } from 'react-bootstrap'
import { Form } from 'tabler-react'
import { Formik } from 'formik'
import { Link, RouteComponentProps } from 'react-router-dom'
import { signUp, loadUser } from '../../redux/actions/authActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../hooks'
import { validation, signUpSchema } from './validation'
import FormCard from '../../components/cards/formCard'
import Logo from '../../assets/images/linkzyLogoWithText.svg'
import Page from '../../layout/page'
import React, { useState, useEffect, Fragment } from 'react'

const SignUp: React.FC<RouteComponentProps> = ({ history }) => {
  const { error, isAuthenticated, loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
    if (!isAuthenticated && localStorage.token) {
      dispatch(loadUser())
    }
    // eslint-disable-next-line
  }, [isAuthenticated, error])

  const [isDirty, setDirty] = useState(false)

  return (
    <Fragment>
      <Formik
        validationSchema={signUpSchema}
        validateOnChange={isDirty}
        onSubmit={values => {
          dispatch(signUp(values))
        }}
        initialValues={{ firstname: '', lastname: '', email: '', password: '' }}
        validate={values => {
          setDirty(true)
          return validation(values)
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Page>
            <div className='page-single'>
              <Container>
                <Row>
                  <Col className='col-login mx-auto'>
                    <div className='text-center mb-6'>
                      <Image src={Logo} />
                    </div>
                    <FormCard title='Sign up' buttonText='Sign up' onSubmit={handleSubmit} loading={loading}>
                      <Form.Group label='Firstname'>
                        <Form.Input
                          type='text'
                          name='firstname'
                          value={values.firstname || ''}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group label='Lastname'>
                        <Form.Input type='text' name='lastname' value={values.lastname || ''} onChange={handleChange} />
                      </Form.Group>

                      <Form.Group label='Email'>
                        <Form.Input
                          type='email'
                          placeholder='Enter email'
                          name='email'
                          value={values.email}
                          onChange={handleChange}
                          error={errors.email}
                          cross={errors.email}
                        />
                      </Form.Group>
                      <Form.Group label='Password'>
                        <Form.Input
                          type='password'
                          placeholder='Enter password'
                          name='password'
                          value={values.password}
                          onChange={handleChange}
                          error={errors.password}
                          cross={errors.password}
                        />
                      </Form.Group>
                    </FormCard>
                    <div className='text-right'>
                      <small>
                        <Link to='/login'>Already have a account?</Link>
                      </small>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Page>
        )}
      </Formik>
    </Fragment>
  )
}

export default SignUp
