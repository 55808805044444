import { EventFormErrors, EventFormValues } from '../../../types'
import { StreamingSolution } from '../../../enums'

const validation = (values: EventFormValues): [boolean, EventFormErrors] => {
  const errors: EventFormErrors = {}

  if (!values.name) errors.name = 'Name required'

  if ([StreamingSolution.link, StreamingSolution.protectedLink].includes(values.streamingSolution)) {
    if (!values.zoomLink) errors.zoomLink = 'Link required'

    if (values.streamingSolution === StreamingSolution.protectedLink && !values.streamingLinkPassword) {
      errors.streamingLinkPassword = 'Password required'
    }
  }

  if (values.streamingSolution === StreamingSolution.zoom && !values.zoomMeetingId) {
    errors.zoomMeetingId = 'Zoom meeting required'
  }

  if (values.paymentSolution === 'stripe') {
    if (!values.stripePrice) errors.stripePrice = 'Price required'
    if (!values.stripeProduct) errors.stripeProduct = 'Product required'
  }

  return [Object.keys(errors).length === 0, errors]
}

export default validation
