import { Event, EventList } from '..'

export enum EventListsActionTypes {
  ADD_EVENT = 'ADD_EVENT',
  ADD_EVENT_LIST = 'ADD_EVENT_LIST',
  CLEAR_EVENTS_ERROR = 'CLEAR_EVENTS_ERROR',
  DELETE_EVENT = 'DELETE_EVENT',
  DELETE_EVENT_LIST = 'DELETE_EVENT_LIST',
  EVENTS_ERROR = 'EVENTS_ERROR',
  RESET_REGISTRATIONS = 'RESET_REGISTRATIONS',
  SET_EVENT_LISTS_LOADING = 'SET_EVENT_LISTS_LOADING',
  SET_EVENTLISTS = 'SET_EVENTLISTS',
  SET_SELECTED_EVENT = 'SET_SELECTED_EVENT',
  SET_SELECTED_EVENTLIST_INDEX = 'SET_SELECTED_EVENTLIST_INDEX',
  UPDATE_EVENT = 'UPDATE_EVENT',
  UPDATE_EVENT_LIST = 'UPDATE_EVENT_LIST'
}

type AddEvent = {
  type: EventListsActionTypes.ADD_EVENT
  payload: { eventListId: string; newEvent: Event }
}

type AddEventList = {
  type: EventListsActionTypes.ADD_EVENT_LIST
  payload: EventList
}

type ClearEventListsError = {
  type: EventListsActionTypes.CLEAR_EVENTS_ERROR
}

type DeleteEvent = {
  type: EventListsActionTypes.DELETE_EVENT
  payload: { eventListId: string; eventId: string }
}

type DeleteEventList = {
  type: EventListsActionTypes.DELETE_EVENT_LIST
  payload: string
}

type EventError = {
  type: EventListsActionTypes.EVENTS_ERROR
  payload: string
}

type ResetRegistrations = {
  type: EventListsActionTypes.RESET_REGISTRATIONS
  payload: { eventListId: string; eventId: string }
}

type SetEventList = {
  type: EventListsActionTypes.SET_EVENTLISTS
  payload: EventList[]
}

type SetLoading = {
  type: EventListsActionTypes.SET_EVENT_LISTS_LOADING
  payload: string
}

type SetSelectedEvent = {
  type: EventListsActionTypes.SET_SELECTED_EVENT
  payload: Event | null
}

type SetSelectedEventListIndex = {
  type: EventListsActionTypes.SET_SELECTED_EVENTLIST_INDEX
  payload: number
}

type UpdateEvent = {
  type: EventListsActionTypes.UPDATE_EVENT
  payload: { eventListId: string; updatedEvent: Event }
}

type UpdateEventList = {
  type: EventListsActionTypes.UPDATE_EVENT_LIST
  payload: EventList
}
export type EventListsAction =
  | AddEvent
  | AddEventList
  | ClearEventListsError
  | DeleteEvent
  | DeleteEventList
  | EventError
  | ResetRegistrations
  | SetEventList
  | SetLoading
  | SetSelectedEvent
  | SetSelectedEventListIndex
  | UpdateEvent
  | UpdateEventList
