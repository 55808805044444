// prettier-ignore
import { addEventList, deleteEventList, setSelectedEventListIndex, updateEventList } from '../../../redux/actions/eventListsActions'
import { Button, Form } from 'tabler-react'
import { Col, Row, Modal } from 'react-bootstrap'
import { EventList, EventListFormErrors, EventListFormValues } from '../../../types'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import API from '../../../api'
import defaultValues from './defaultValues'
import initialState from '../../../redux/initialState'
import React, { ChangeEvent, useState } from 'react'
import validation from './validation'

type Props = {
  eventList: EventList | null
  show: boolean | string
  setShowModal: (show: '' | 'add' | 'edit' | false) => void
}

const EventListModal: React.FC<Props> = ({ eventList, show, setShowModal }) => {
  const { loading } = useSelector(state => state.eventLists)
  const [errors, setErrors] = useState<EventListFormErrors>({})
  const [isDirty, setIsDirty] = useState(false)
  const [values, setValues] = useState<EventListFormValues>(
    eventList ? { ...defaultValues, name: eventList.name } : defaultValues
  )
  const dispatch = useDispatch()

  const onAdd = async () => {
    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    const newEventList = await API.EventLists.createEventList(values)

    if (newEventList) {
      dispatch(addEventList(newEventList))

      setShowModal(false)
    }
  }

  const onDelete = async () => {
    if (!eventList) return

    const success = await API.EventLists.deleteEventList(eventList.id)

    if (success) {
      dispatch(deleteEventList(eventList.id))
      dispatch(setSelectedEventListIndex(initialState.eventLists.selectedEventListIndex))

      setShowModal(false)
    }
  }

  const onUpdate = async () => {
    if (!eventList) return

    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    const updatedEventList = await API.EventLists.updateEventList(eventList.id, { ...eventList, ...values })

    if (updatedEventList) {
      dispatch(updateEventList(updatedEventList))

      setShowModal(false)
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newValues = { ...values, [name]: value }

    setValues(newValues)

    isDirty && setErrors(validation(newValues)[1])
  }

  return (
    <Modal centered size='sm' show={show} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>{eventList ? 'Edit event list' : 'Add event list'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group label='Name'>
              <Form.Input
                type='text'
                name='name'
                value={values.name || ''}
                invalid={errors.name}
                cross={errors.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {Object.keys(values).length ? (
          <Row>
            <Col>
              <Button
                type='button'
                color='danger'
                loading={loading === 'deleteEventList'}
                disabled={loading}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Col>
          </Row>
        ) : null}
        <Col style={{ padding: '0px' }}>
          <Button
            className='float-right'
            type='button'
            color='primary'
            loading={loading === 'addEventList'}
            disabled={loading}
            onClick={() => (eventList ? onUpdate() : onAdd())}
          >
            {eventList ? 'Update' : 'Add'}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  )
}

export default EventListModal
