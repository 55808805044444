import { Event, EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const setSelectedEvent = (
  event: Event | null
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => dispatch => {
  dispatch({
    type: EventListsActionTypes.SET_SELECTED_EVENT,
    payload: event
  })
}
