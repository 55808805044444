import React from 'react'

type Props = {
  children?: React.ReactNode
}

const PageMapHeader: React.FC<Props> = ({ children }) => <div className='map-header'>{children}</div>

PageMapHeader.displayName = 'Page.MapHeader'

export default PageMapHeader
