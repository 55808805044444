import { getPublishableKeyMode } from '../../enums'
import { GetProductsResponse, PublicProduct } from '../../types'
import { Image } from 'react-bootstrap'
import { loadStripe } from '@stripe/stripe-js'
import { RouteComponentProps, useParams } from 'react-router-dom'
import API from '../../api'
import ErrorPage from '../errorPage'
import ProductCard from '../../components/cards/productCard'
import Loader from '../../components/loader'
import Logo from '../../assets/images/linkzyLogoWithText.svg'
import Page from '../../layout/page'
import React, { useEffect, useState } from 'react'

const ProductList: React.FC<RouteComponentProps> = ({ history }) => {
  const id = useParams<{ id: string }>().id

  const [error, setError] = useState(false)
  const [productList, setProductList] = useState<GetProductsResponse | null>(null)
  const [loading, setLoading] = useState('')
  const [pageLoading, setPageLoading] = useState(true)

  const goToCheckout = async (product: PublicProduct) => {
    setLoading(product.id)

    const sessionId = await API.Products.getCheckoutSessionId(id, product.id)
    if (!sessionId) return

    const publishableKey = await API.Stripe.getPublishableKey(id, getPublishableKeyMode.product)
    if (!publishableKey) return

    const stripe = await loadStripe(publishableKey)

    stripe &&
      stripe.redirectToCheckout({
        sessionId
      })

    setLoading('')
  }

  const retreiveProducts = async () => {
    const productList = await API.Products.getProducts(id)

    if (productList) setProductList(productList)
    else setError(true)

    setPageLoading(false)
  }

  useEffect(() => {
    retreiveProducts()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='public_list'>
      {pageLoading ? (
        <Loader />
      ) : error ? (
        <ErrorPage title='Sorry' subtitle='This page does not exists' />
      ) : productList ? (
        <>
          <div className='logo-container'>
            <a className='logo header-brand' href='#' onClick={() => history.push('/')}>
              <Image src={Logo} className='header-brand-img' />
            </a>
          </div>
          <Page.Content title={productList.name}>
            {productList.products.map(product => (
              <ProductCard
                key={product.id}
                product={product}
                loading={loading}
                onClick={() => goToCheckout(product)}
                socialMedia={productList.socialMedia}
              />
            ))}
          </Page.Content>
        </>
      ) : null}
    </div>
  )
}

export default ProductList
