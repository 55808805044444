import { Row } from 'react-bootstrap'
import { Loader as CircularLoader } from 'tabler-react'
import React from 'react'

const Loader: React.FC = () => {
  return (
    <Row className='h-100 justify-content-center align-items-center'>
      <CircularLoader />
    </Row>
  )
}

export default Loader
