export * from './api'
export * from './events'
export * from './forms'
export * from './products'
export * from './redux'
export * from './stripe'
export * from './zoom'

import { Role, Service } from '../enums'

export type SocialMedia = {
  facebook?: string
  instagram?: string
  linkedIn?: string
  twitter?: string
}

export type User = {
  firstname: string
  lastname: string
  services: Service[]
  socialMedia: SocialMedia
  uid: string
  role: Role
  roles: Role[]
}
