import { IGlobalState, Product, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const setSelectedProduct = (
  product: Product | null
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => dispatch => {
  dispatch({
    type: ProductListsActionTypes.SET_SELECTED_PRODUCT,
    payload: product
  })
}
