import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'
import initialState from './initialState'
import rootReducer from './reducers'
import thunk from 'redux-thunk'

const middleware = [thunk]

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
})

const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)))

export default store
