import React from 'react'

type Props = {
  children?: React.ReactNode
}

const PageMain: React.FC<Props> = ({ children }) => <div className='page-main'>{children}</div>

PageMain.displayName = 'Page.Main'

export default PageMain
