import { EventListFormErrors, EventListFormValues } from '../../../types'

const validation = (values: EventListFormValues): [boolean, EventListFormErrors] => {
  const errors: EventListFormErrors = {}

  if (!values.name) errors.name = 'Name required'

  return [Object.keys(errors).length === 0, errors]
}

export default validation
