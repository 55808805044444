import { AuthAction, AuthActionsTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'
import setLoading from './setLoading'

export const sendPasswordResetEmail = (
  email: string
): ThunkAction<void, IGlobalState, unknown, AuthAction> => async dispatch => {
  dispatch(setLoading('sendPasswordResetEmail'))

  const success = await API.Auth.sendPasswordResetEmail(email)

  if (success) {
    dispatch({
      type: AuthActionsTypes.RESET_PASSWORD
    })
  } else {
    dispatch({
      type: AuthActionsTypes.AUTH_ERROR,
      payload: 'sendPasswordResetEmail error'
    })

    setTimeout(() => dispatch({ type: AuthActionsTypes.CLEAR_ERRORS }), 5000)
  }
}
