export enum StripePriceType {
  one_time = 'one_time',
  recurring = 'recurring'
}

export enum Currency {
  EUR = 'eur',
  USD = 'usd'
}

export enum PaymentStatus {
  noPaymentRequired = 'no_payment_required',
  paid = 'paid',
  unpaid = 'unpaid'
}
