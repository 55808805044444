import { Button, Form } from 'tabler-react'
import { Col, Row } from 'react-bootstrap'
import { defaultValues } from './defaultValues'
import { getFormatedStripePrice } from '../../../utils/stripe'
import { newProduct } from '../../../models'
import { NewProduct, Product, ProductFormErrors, ProductFormValues } from '../../../types'
import { Service, StripePriceType } from '../../../enums'
import { StripePrice, StripeProduct } from '../../../types/stripe'
import { useSelector } from '../../../hooks'
import API from '../../../api'
import React, { ChangeEvent, useEffect, useState } from 'react'
import validation from './validation'

type Props = {
  product?: Product | null
  onDelete?: () => void
  onSubmit: (product: NewProduct) => void
}

const ProductForm: React.FC<Props> = ({ product, onDelete, onSubmit }) => {
  const services = useSelector(state => state.auth.user?.services)
  const [errors, setErrors] = useState<ProductFormErrors>({})
  const [isDirty, setIsDirty] = useState(false)
  const [stripeProducts, setStripeProducts] = useState<StripeProduct[]>([])
  const [stripePrices, setStripePrices] = useState<StripePrice[]>([])
  const [values, setValues] = useState<ProductFormValues>(
    product
      ? {
          ...defaultValues,
          ...product
        }
      : defaultValues
  )

  useEffect(() => {
    services && services.includes(Service.stripe) && getStripeProducts()
    // eslint-disable-next-line
  }, [services])

  const getStripeProducts = async () => {
    const [products, prices] = await Promise.all([API.Stripe.getProducts(), API.Stripe.getPrices()])

    products && setStripeProducts(products)
    prices && setStripePrices(prices)

    products.length &&
      setValues({
        ...values,
        stripeProduct: values.stripeProduct || products[0],
        stripePrice:
          values.stripePrice ||
          prices.filter(price => price.product === products[0].id && price.type === StripePriceType.one_time)[0]
      })
  }

  const handleSubmit = async () => {
    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    const product = newProduct(values)

    product && onSubmit(product)
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group label='Product'>
            <Form.Select
              value={values.stripeProduct?.name}
              onChange={(e: ChangeEvent<HTMLOptionsCollection>) => {
                setValues({
                  ...values,
                  stripeProduct: stripeProducts[e.target.selectedIndex],
                  stripePrice: stripePrices.filter(
                    price =>
                      price.product === stripeProducts[e.target.selectedIndex].id &&
                      price.type === StripePriceType.one_time
                  )[0]
                })
              }}
              invalid={errors.stripeProduct}
              cross={errors.stripeProduct}
            >
              {stripeProducts.map(product => (
                <option key={product.name} value={product.name}>
                  {product.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group label='Price'>
            <Form.Select
              value={values.stripePrice ? getFormatedStripePrice(values.stripePrice) : ''}
              onChange={(e: ChangeEvent<HTMLOptionsCollection>) => {
                setValues({
                  ...values,
                  stripePrice: stripePrices.filter(
                    price => price.product === values.stripeProduct?.id && price.type === StripePriceType.one_time
                  )[e.target.selectedIndex]
                })
              }}
              invalid={errors.stripePrice}
              cross={errors.stripePrice}
            >
              {stripePrices
                .filter(price => price.product === values.stripeProduct?.id && price.type === StripePriceType.one_time)
                .map(price => (
                  <option key={price.id} value={getFormatedStripePrice(price)}>
                    {getFormatedStripePrice(price)}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {product ? (
            <Button color='danger' onClick={onDelete}>
              Delete
            </Button>
          ) : null}
          <Button className='float-right' color='primary' onClick={handleSubmit}>
            {product ? 'Update product' : 'Add product'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ProductForm
