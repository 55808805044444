import { Service } from '../../enums'
import { GetNewTokenResponse, LoginResponse, SocialMedia, User } from '../../types'

export enum AuthActionsTypes {
  ACTION_CODE_VALID = 'ACTION_CODE_VALID',
  ADD_SERVICE = 'ADD_SERVICE',
  AUTH_ERROR = 'AUTH_ERROR',
  CLEAR_ERRORS = 'CLEAR_ERRORS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
  PASSWORD_RESETED = 'PASSWORD_RESETED',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SET_LOADING = 'SET_LOADING',
  SIGN_UP_FAIL = 'SIGN_UP_FAIL',
  SIGN_UP_SUCCESSS = 'SIGN_UP_SUCCESSS',
  UPDATE_SOCIAL_MEDIA = 'UPDATE_SOCIAL_MEDIA',
  USER_LOADED = 'USER_LOADED'
}

type ActionCodeValid = {
  type: AuthActionsTypes.ACTION_CODE_VALID
}

type AddService = {
  type: AuthActionsTypes.ADD_SERVICE
  payload: Service
}

type AuthError = {
  type: AuthActionsTypes.AUTH_ERROR
  payload: string
}

type ClearError = {
  type: AuthActionsTypes.CLEAR_ERRORS
}

type LoginFail = {
  type: AuthActionsTypes.LOGIN_FAIL
  payload: string
}

type LoginSuccess = {
  type: AuthActionsTypes.LOGIN_SUCCESS
  payload: LoginResponse
}

type Logout = {
  type: AuthActionsTypes.LOGOUT
}

type PasswordReseted = {
  type: AuthActionsTypes.PASSWORD_RESETED
  payload: LoginResponse
}

type RefreshToken = {
  type: AuthActionsTypes.REFRESH_TOKEN
  payload: GetNewTokenResponse
}

type ResetPassword = {
  type: AuthActionsTypes.RESET_PASSWORD
}

type SetLoading = {
  type: AuthActionsTypes.SET_LOADING
  payload: string
}

type SignUpFail = {
  type: AuthActionsTypes.SIGN_UP_FAIL
  payload: string
}

type SignUpSuccess = {
  type: AuthActionsTypes.SIGN_UP_SUCCESSS
  payload: LoginResponse
}

type UpdateSocialMedia = {
  type: AuthActionsTypes.UPDATE_SOCIAL_MEDIA
  payload: SocialMedia
}

type UserLoaded = {
  type: AuthActionsTypes.USER_LOADED
  payload: User
}

export type AuthAction =
  | ActionCodeValid
  | AddService
  | AuthError
  | ClearError
  | LoginFail
  | LoginSuccess
  | Logout
  | PasswordReseted
  | RefreshToken
  | ResetPassword
  | SetLoading
  | SignUpFail
  | SignUpSuccess
  | UpdateSocialMedia
  | UserLoaded
