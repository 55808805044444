import { ZoomFormErrors, ZoomFormValues } from '../../../types'

const validation = (values: ZoomFormValues): [boolean, ZoomFormErrors] => {
  const errors: ZoomFormErrors = {}

  if (!values.token) errors.token = 'Token invalid'

  return [Object.keys(errors).length === 0, errors]
}

export default validation
