import React from 'react'

type Props = {
  children?: React.ReactNode
  className?: string
}

const PageTitle: React.FC<Props> = ({ children, className }) => (
  <h1 className={`page-title ${className}`}>{children}</h1>
)

PageTitle.displayName = 'Page.Title'

export default PageTitle
