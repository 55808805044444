import { EventListsAction, EventListsActionTypes, EventListReducer } from '../../types'
import initialState from '../initialState'

const eventListsReducer = (state = initialState.eventLists, action: EventListsAction): EventListReducer => {
  switch (action.type) {
    case EventListsActionTypes.ADD_EVENT_LIST:
      return {
        ...state,
        eventLists: [...state.eventLists, action.payload]
      }
    case EventListsActionTypes.ADD_EVENT: {
      const { eventListId, newEvent } = action.payload

      return {
        ...state,
        eventLists: state.eventLists.map(eventList =>
          eventList.id === eventListId ? { ...eventList, events: [...eventList.events, newEvent] } : eventList
        )
      }
    }
    case EventListsActionTypes.DELETE_EVENT: {
      const { eventListId, eventId } = action.payload

      return {
        ...state,
        eventLists: state.eventLists.map(eventList =>
          eventList.id === eventListId
            ? { ...eventList, events: eventList.events.filter(event => event.id !== eventId) }
            : eventList
        )
      }
    }
    case EventListsActionTypes.DELETE_EVENT_LIST:
      return {
        ...state,
        eventLists: state.eventLists.filter(eventList => eventList.id !== action.payload)
      }
    case EventListsActionTypes.RESET_REGISTRATIONS: {
      const { eventListId, eventId } = action.payload

      return {
        ...state,
        eventLists: state.eventLists.map(eventList =>
          eventList.id === eventListId
            ? {
                ...eventList,
                events: eventList.events.map(event => (event.id === eventId ? { ...event, registrations: [] } : event))
              }
            : eventList
        ),
        loading: false
      }
    }
    case EventListsActionTypes.UPDATE_EVENT: {
      const { eventListId, updatedEvent } = action.payload

      return {
        ...state,
        eventLists: state.eventLists.map(eventList =>
          eventList.id === eventListId
            ? {
                ...eventList,
                events: eventList.events.map(event => (event.id === updatedEvent.id ? updatedEvent : event))
              }
            : eventList
        )
      }
    }
    case EventListsActionTypes.UPDATE_EVENT_LIST:
      return {
        ...state,
        eventLists: state.eventLists.map(eventList =>
          eventList.id === action.payload.id ? { ...eventList, name: action.payload.name } : eventList
        )
      }
    case EventListsActionTypes.CLEAR_EVENTS_ERROR:
      return {
        ...state,
        error: null
      }
    case EventListsActionTypes.SET_EVENTLISTS:
      return {
        ...state,
        eventLists: action.payload
      }
    case EventListsActionTypes.SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.payload
      }
    case EventListsActionTypes.SET_SELECTED_EVENTLIST_INDEX:
      return {
        ...state,
        selectedEventListIndex: action.payload
      }
    case EventListsActionTypes.SET_EVENT_LISTS_LOADING:
      return {
        ...state,
        loading: action.payload ?? true
      }
    case EventListsActionTypes.EVENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}

export default eventListsReducer
