import { IGlobalState, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const deleteProductList = (
  productListId: string
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => async dispatch => {
  dispatch({
    type: ProductListsActionTypes.DELETE_PRODUCT_LIST,
    payload: productListId
  })
}
