import { useHistory, useLocation } from 'react-router-dom'
import { PaymentSuccessMode } from '../../../enums'
import ErrorPage from '../../../pages/errorPage'
import React, { useEffect } from 'react'

const PaymentSuccess: React.FC = () => {
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)
  const listId = params.get('code')?.split('@')[1]
  const redirectTo = params.get('redirectTo')
  const mode = params.get('mode')

  useEffect(() => {
    setTimeout(() => {
      if (redirectTo) window.location.href = redirectTo
      else {
        mode === PaymentSuccessMode.event && history.push(`/events/${listId}`)
        mode === PaymentSuccessMode.product && history.push(`/products/${listId}`)
      }
    }, 5000)
    // eslint-disable-next-line
  }, [])

  return redirectTo ? (
    <ErrorPage
      title='Thank you'
      subtitle='A confirmation has been sent to you by email'
      details='If you are not being redirected to the event within a few seconds, click on the button below'
      buttonText='Join event'
      buttonAction={() => {
        if (redirectTo) window.location.href = redirectTo
        else history.push(`/events/${listId}`)
      }}
    />
  ) : (
    <ErrorPage
      title='Thank you for your purchase'
      subtitle={
        mode === PaymentSuccessMode.event
          ? 'Your are now registered to this event'
          : 'A confirmation has been sent to you by email'
      }
    />
  )
}

export default PaymentSuccess
