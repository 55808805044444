import { addService } from '../../../redux/actions/authActions'
import { Button, Form } from 'tabler-react'
import { Col, Row, Modal } from 'react-bootstrap'
import { hideModal } from '../../../redux/actions/modalsActions'
import { Service } from '../../../enums'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import { ZoomFormErrors } from '../../../types'
import API from '../../../api'
import defaultValues from './defaultValues'
import React, { ChangeEvent, useEffect, useState } from 'react'
import validation from './validation'

const ZoomTokenModal: React.FC = () => {
  const [errors, setErrors] = useState<ZoomFormErrors>({})
  const [loading, setLoading] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [show, setShow] = useState(true)
  const [values, setValues] = useState(defaultValues)
  const services = useSelector(state => state.auth.user?.services)
  const dispatch = useDispatch()

  const onHide = () => {
    setShow(false)
    setTimeout(() => dispatch(hideModal()), 500)
  }

  const onSubmit = async () => {
    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    setLoading(true)

    const success = await API.Zoom.sendToken(values.token)

    if (success) {
      dispatch(addService(Service.zoom))

      onHide()
    } else setErrors({ token: 'Token invalid' })

    setLoading(false)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newValues = { ...values, [name]: value }

    setValues(newValues)

    isDirty && setErrors(validation(newValues)[1])
  }

  useEffect(() => {
    window.onpopstate = () => onHide()

    return () => {
      window.onpopstate = null
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Modal centered size='lg' onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>
          {services?.includes(Service.zoom) ? 'Update your Zoom token' : 'Link your Zoom account'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h5>
              Follow the{' '}
              <a href='https://marketplace.zoom.us/docs/guides/build/jwt-app'>
                <span style={{ color: 'blue' }}>official procedure</span>
              </a>{' '}
              to create a JWT token
            </h5>

            {!services?.includes(Service.zoom) && (
              <h5 className='mt-5'>You can update your token via the settings page</h5>
            )}

            <Form.Group label='Token' className='mt-5'>
              <Form.Input
                type='text'
                name='token'
                value={values.token}
                disabled={loading}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
                feedback='Token invalid'
                error={errors.token}
                cross={errors.token}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='ml-auto'
          loading={loading}
          disabled={loading}
          type='button'
          color='primary'
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ZoomTokenModal
