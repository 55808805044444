import { EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'

export const deleteEvent = (
  eventListId: string,
  eventId: string
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => async dispatch => {
  const success = await API.Events.deleteEvent(eventListId, eventId)

  if (success) {
    dispatch({
      type: EventListsActionTypes.DELETE_EVENT,
      payload: { eventListId, eventId }
    })
  } else {
    dispatch({
      type: EventListsActionTypes.EVENTS_ERROR,
      payload: 'deleteEvent error'
    })

    setTimeout(() => dispatch({ type: EventListsActionTypes.CLEAR_EVENTS_ERROR }), 5000)
  }
}
