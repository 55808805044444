// prettier-ignore
import { addProduct, deleteProduct, setSelectedProduct, updateProduct } from '../../../redux/actions/productListsActions'
import { NewProduct } from '../../../types'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import Page from '../../../layout/page'
import ProductForm from '../../../components/forms/productForm'
import React, { useEffect } from 'react'

const Product: React.FC<RouteComponentProps> = ({ history }) => {
  const { selectedProduct } = useSelector(state => state.productLists)
  const productList = useSelector(state => state.productLists.productLists[state.productLists.selectedProductListIndex])
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setSelectedProduct(null))
    }
    // eslint-disable-next-line
  }, [])

  if (!productList) {
    history.push('/products')
    return null
  }

  const onDelete = async () => {
    selectedProduct && (await dispatch(deleteProduct(productList.id, selectedProduct.id)))

    history.push('/products')
  }

  const onSubmit = async (product: NewProduct) => {
    !selectedProduct && dispatch(addProduct(productList.id, product))
    selectedProduct && dispatch(updateProduct(productList.id, { ...product, id: selectedProduct.id }))

    history.push('/products')
  }

  return (
    <Page.Content title={selectedProduct ? selectedProduct.stripeProduct.name : 'New product'}>
      <ProductForm product={selectedProduct} onDelete={onDelete} onSubmit={onSubmit} />
    </Page.Content>
  )
}

export default Product
