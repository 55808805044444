import { GetNewTokenResponse, LoginResponse, SignUp, User } from '../../types'
import { ActionCodeMode } from '../../enums'
import axios from 'axios'

const API = process.env.REACT_APP_API_ENDPOINT

export const getNewToken = async (refreshToken: string): Promise<GetNewTokenResponse | false> => {
  try {
    const Axios = axios.create()
    const { data } = await Axios.post<GetNewTokenResponse>(`${API}/api/v1/auth/refreshToken`, { refreshToken })

    const { token, expiresAt } = data

    return { token, expiresAt }
  } catch (error) {
    return false
  }
}

export const getUser = async (): Promise<User | false> => {
  try {
    const { data } = await axios.get<{ user: User }>(`${API}/api/v1/auth`)

    return data.user
  } catch (error) {
    return false
  }
}

export const login = async (email: string, password: string): Promise<LoginResponse | false> => {
  try {
    const Axios = axios.create()
    const { data } = await Axios.post<LoginResponse>(`${API}/api/v1/auth`, { email, password })

    return data
  } catch (error) {
    return false
  }
}

export const resetPassword = async (actionCode: string, password: string): Promise<LoginResponse | false> => {
  try {
    const Axios = axios.create()
    const { data } = await Axios.post<LoginResponse>(`${API}/api/v1/auth/resetPassword`, { actionCode, password })

    return data
  } catch (error) {
    return false
  }
}

export const sendPasswordResetEmail = async (email: string): Promise<boolean> => {
  try {
    const Axios = axios.create()
    await Axios.head(`${API}/api/v1/auth/resetPassword/${email}`)

    return true
  } catch (error) {
    return false
  }
}

export const signUp = async (values: SignUp): Promise<LoginResponse | false> => {
  try {
    const Axios = axios.create()
    const { data } = await Axios.post<LoginResponse>(`${API}/api/v1/auth/signup`, values)

    return data
  } catch (error) {
    return false
  }
}

export const verifyActionCode = async (mode: ActionCodeMode, actionCode: string): Promise<boolean> => {
  try {
    const Axios = axios.create()
    await Axios.post(`${API}/api/v1/auth/emailActions`, { mode, actionCode })

    return true
  } catch (error) {
    return false
  }
}
