import { IGlobalState, ProductList, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const addProductList = (
  newProductList: ProductList
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => async dispatch => {
  dispatch({
    type: ProductListsActionTypes.ADD_PRODUCT_LIST,
    payload: newProductList
  })
}
