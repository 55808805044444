import { addService } from '../../../../redux/actions/authActions'
import { RouteComponentProps } from 'react-router-dom'
import { Service } from '../../../../enums'
import { useDispatch } from 'react-redux'
import API from '../../../../api'
import Loader from '../../../../components/loader'
import React, { useEffect } from 'react'

const Redirect: React.FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch()
  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const mode = params.get('mode')

  const sendStripeCode = async () => {
    if (!code) return

    const success = await API.Stripe.postAuthCode(code)

    if (success) {
      dispatch(addService(Service.stripe))
      history.push('/')
    } else history.push('/?error=stripe')
  }

  useEffect(() => {
    mode === 'stripe' && sendStripeCode()
    // eslint-disable-next-line
  }, [])

  return <Loader />
}

export default Redirect
