import { AuthAction, AuthActionsTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'
import setLoading from './setLoading'

export const loadUser = (): ThunkAction<void, IGlobalState, unknown, AuthAction> => async dispatch => {
  dispatch(setLoading('loadUser'))

  const user = await API.Auth.getUser()

  if (user) {
    dispatch({
      type: AuthActionsTypes.USER_LOADED,
      payload: user
    })
  } else {
    dispatch({ type: AuthActionsTypes.AUTH_ERROR, payload: 'getUser error' })

    setTimeout(() => dispatch({ type: AuthActionsTypes.CLEAR_ERRORS }), 5000)
  }
}
