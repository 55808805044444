import { StripePrice } from '../../types'

export const getFormatedStripePrice = (price: StripePrice): string => {
  const isPriceRound = price.unit_amount_decimal.slice(price.unit_amount_decimal.length - 2) === '00'

  return isPriceRound
    ? `${price.unit_amount_decimal.slice(0, price.unit_amount_decimal.length - 2)} ${
        price.currency === 'eur' ? '€' : price.currency
      }`
    : `${price.unit_amount_decimal.slice(0, price.unit_amount_decimal.length - 2)}.${price.unit_amount_decimal.slice(
        price.unit_amount_decimal.length - 2
      )} ${price.currency === 'eur' ? '€' : price.currency}`
}
