import { AuthActionsTypes, IGlobalState, ReduxAction } from '../../types'
import { combineReducers, Reducer } from 'redux'
import authReducer from './authReducer'
import eventListsReducer from './eventListsReducer'
import modalsReducer from './modalsReducer'
import productListsReducer from './productListsReducer'
import initialState from '../initialState'

const appReducer = combineReducers({
  auth: authReducer,
  eventLists: eventListsReducer,
  modals: modalsReducer,
  productLists: productListsReducer
})

const reducer: Reducer<IGlobalState, ReduxAction> = (state, action: ReduxAction) => {
  switch (action.type) {
    case AuthActionsTypes.LOGOUT:
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('expiresAt')

      return initialState
    // eslint-disable-next-line
    default:
      return appReducer(state, action)
  }
}

export default reducer
