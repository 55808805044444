import { AuthAction, AuthActionsTypes, AuthReducer } from '../../types'
import initialState from '../initialState'

const authReducer = (state = initialState.auth, action: AuthAction): AuthReducer => {
  switch (action.type) {
    case AuthActionsTypes.USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload
      }
    case AuthActionsTypes.SIGN_UP_SUCCESSS:
    case AuthActionsTypes.LOGIN_SUCCESS: {
      const { token, refreshToken, expiresAt } = action.payload

      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('expiresAt', expiresAt.toString())

      return {
        ...state,
        token,
        refreshToken,
        expiresAt,
        isAuthenticated: true,
        loading: false
      }
    }
    case AuthActionsTypes.REFRESH_TOKEN: {
      const { token, expiresAt } = action.payload

      localStorage.setItem('token', token)
      localStorage.setItem('expiresAt', expiresAt.toString())

      return {
        ...state,
        token,
        expiresAt
      }
    }
    case AuthActionsTypes.AUTH_ERROR:
    case AuthActionsTypes.SIGN_UP_FAIL:
    case AuthActionsTypes.LOGIN_FAIL:
    case AuthActionsTypes.LOGOUT:
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('expiresAt')

      return initialState.auth
    case AuthActionsTypes.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordEmailSent: true,
        loading: false
      }
    case AuthActionsTypes.PASSWORD_RESETED:
      const { token, refreshToken, expiresAt } = action.payload

      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('expiresAt', expiresAt.toString())

      return {
        ...state,
        token,
        refreshToken,
        expiresAt,
        isAuthenticated: true,
        passwordReseted: true,
        loading: false
      }
    case AuthActionsTypes.ACTION_CODE_VALID:
      return {
        ...state,
        actionCodeValid: true,
        loading: false
      }
    case AuthActionsTypes.ADD_SERVICE:
      return {
        ...state,
        user: state.user
          ? state.user.services.includes(action.payload)
            ? state.user
            : { ...state.user, services: [...state.user.services, action.payload] }
          : state.user
      }
    case AuthActionsTypes.UPDATE_SOCIAL_MEDIA:
      return {
        ...state,
        user: state.user ? { ...state.user, socialMedia: action.payload } : null,
        loading: false
      }
    case AuthActionsTypes.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      }
    case AuthActionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload ?? true
      }
    default:
      return state
  }
}

export default authReducer
