import { Env } from '../enums'
import { getBearer } from './auth'
import axios from 'axios'

const axiosConfig = (): void => {
  axios.interceptors.request.use(
    async config =>
      config.url && config.url.includes(Env.API_ENDPOINT)
        ? {
            ...config,
            headers: {
              ...config.headers,
              Authorization: await getBearer(),
              'Content-Type': 'application/json'
            }
          }
        : config,
    error => Promise.reject(error)
  )
}

export default axiosConfig
