import { EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'
import setLoading from './setLoading'

export const resetRegistrations = (
  eventListId: string,
  eventId: string
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => async dispatch => {
  dispatch(setLoading('resetRegistrations'))

  const success = await API.Events.resetRegistrations(eventListId, eventId)

  if (success) {
    dispatch({
      type: EventListsActionTypes.RESET_REGISTRATIONS,
      payload: { eventListId, eventId }
    })
  } else {
    dispatch({
      type: EventListsActionTypes.EVENTS_ERROR,
      payload: 'resetRegistrations error'
    })
  }
}
