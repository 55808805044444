import { Button } from 'tabler-react'
import { CardMode } from '../../../enums'
import { getFormatedStripePrice } from '../../../utils/stripe'
import { Product, PublicProduct, SocialMedia } from '../../../types'
import React from 'react'
import '../../../styles/productCard.css'

type Props =
  | {
      className?: string
      loading?: string
      mode?: CardMode
      onClick: () => void
      product: Product
      socialMedia?: SocialMedia
      style?: Record<string, unknown>
    }
  | {
      className?: string
      loading?: string
      mode: undefined
      onClick: () => void
      product: PublicProduct
      socialMedia?: SocialMedia
      style?: Record<string, unknown>
    }

const ProductCard: React.FC<Props> = ({ className, loading, mode, onClick, product, socialMedia, style }) => {
  const name = product.stripeProduct.name
  const description = product.stripeProduct.description
  const price = getFormatedStripePrice(product.stripePrice)
  // const tags = ['Renfo', 'Musculation', 'Abs', 'e', 't']
  const background = product.stripeProduct.images.length ? product.stripeProduct.images[0] : null

  return (
    <div className={`movie_card ${className}`} style={style} id='bright'>
      <div className='info_section'>
        <div className='movie_header'>
          {/* Place Coach Logo Here? */}
          {/* <img
            className='locandina'
            src='https://movieplayer.net-cdn.it/t/images/2017/12/20/bright_jpg_191x283_crop_q85.jpg'
          /> */}
          <h1>{name}</h1>
          {/* <h4>SubTitle</h4> */}
          <span className='minutes'>{price}</span>

          {/* Tags */}
          {/* <p className='type'>
            {tags.slice(0, 3).map((tag, index) => {
              return index < 2 ? `${tag}, ` : `${tag}`
            })}
          </p> */}
        </div>
        <div className='movie_desc'>
          <p className='text'>{description}</p>
        </div>
        <SocialMediaItems socialMedia={socialMedia} />
      </div>
      <div className='blur_back' style={{ background: `url(${background})` }}></div>
      {mode === CardMode.edit ? (
        <Button size='lg' className='ml-auto mt-3' color='primary' onClick={onClick}>
          Edit
        </Button>
      ) : (
        <Button size='lg' color='primary' onClick={onClick} disabled={loading} loading={loading === product.id}>
          Buy
        </Button>
      )}
    </div>
  )
}

export default ProductCard

const SocialMediaItems: React.FC<{ socialMedia?: SocialMedia }> = ({ socialMedia }) => (
  <div className='movie_social'>
    {socialMedia ? (
      <ul>
        {socialMedia.facebook ? (
          <li>
            <div
              onClick={() => {
                if (socialMedia.facebook) window.location.href = socialMedia.facebook
              }}
            >
              <i className='fe fe-facebook' />
            </div>
          </li>
        ) : null}
        {socialMedia.instagram ? (
          <li>
            <div
              onClick={() => {
                if (socialMedia.instagram) window.location.href = socialMedia.instagram
              }}
            >
              <i className='fe fe-instagram' />
            </div>
          </li>
        ) : null}
        {socialMedia.linkedIn ? (
          <li>
            <div
              onClick={() => {
                if (socialMedia.linkedIn) window.location.href = socialMedia.linkedIn
              }}
            >
              <i className='fe fe-linkedin' />
            </div>
          </li>
        ) : null}
        {socialMedia.twitter ? (
          <li>
            <div
              onClick={() => {
                if (socialMedia.twitter) window.location.href = socialMedia.twitter
              }}
            >
              <i className='fe fe-twitter' />
            </div>
          </li>
        ) : null}
      </ul>
    ) : (
      <ul>
        <li></li>
      </ul>
    )}
  </div>
)
