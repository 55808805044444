import React from 'react'

type Props = {
  children?: React.ReactNode
}

const PageSubTitle: React.FC<Props> = ({ children }) => <div className='page-subtitle'>{children}</div>

PageSubTitle.displayName = 'Page.SubTitle'

export default PageSubTitle
