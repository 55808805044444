import { IGlobalState, ProductList, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const updateProductList = (
  updatedProductList: ProductList
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => async dispatch => {
  dispatch({
    type: ProductListsActionTypes.UPDATE_PRODUCT_LIST,
    payload: updatedProductList
  })
}
