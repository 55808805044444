import { Event, EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'

export const updateEvent = (
  eventListId: string,
  event: Event
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => async dispatch => {
  const updatedEvent = await API.Events.updateEvent(eventListId, event)

  if (updatedEvent) {
    dispatch({
      type: EventListsActionTypes.UPDATE_EVENT,
      payload: { eventListId, updatedEvent }
    })
  } else {
    dispatch({
      type: EventListsActionTypes.EVENTS_ERROR,
      payload: 'updateEvent error'
    })
  }
}
