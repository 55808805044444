import { RegistrationFormErrors, RegistrationFormValues } from '../../../types'
import { isValidEmail } from '../../../utils/strings'

const validation = (values: RegistrationFormValues): [boolean, RegistrationFormErrors] => {
  const errors: RegistrationFormErrors = {}

  if (!isValidEmail(values.email)) errors.email = 'Name required'

  return [Object.keys(errors).length === 0, errors]
}

export default validation
