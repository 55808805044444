import { getPublishableKeyMode } from '../../enums'
import { StripePrice, StripeProduct, StripeSession } from '../../types'
import axios from 'axios'

const API = process.env.REACT_APP_API_ENDPOINT

export const getPaymentSession = async (eventListId: string, eventId: string): Promise<StripeSession | false> => {
  try {
    const { data } = await axios.get<{ session: StripeSession }>(
      `${API}/api/v1/stripe/checkout/${eventListId}/${eventId}`
    )

    return data.session
  } catch (error) {
    return false
  }
}

export const getPrices = async (): Promise<StripePrice[]> => {
  try {
    const { data } = await axios.get<{ prices: StripePrice[] }>(`${API}/api/v1/stripe/prices`)

    return data.prices
  } catch (error) {
    return []
  }
}

export const getProducts = async (): Promise<StripeProduct[]> => {
  try {
    const { data } = await axios.get<{ products: StripeProduct[] }>(`${API}/api/v1/stripe/products`)

    return data.products
  } catch (error) {
    return []
  }
}

export const getPublishableKey = async (
  eventListOrProductListId: string,
  mode: getPublishableKeyMode
): Promise<string | false> => {
  try {
    const { data } = await axios.get<{ publishableKey: string }>(
      `${API}/api/v1/stripe/${eventListOrProductListId}?mode=${mode}`
    )

    return data.publishableKey
  } catch (error) {
    return false
  }
}

export const postAuthCode = async (code: string): Promise<boolean> => {
  try {
    await axios.post(`${API}/api/v1/stripe/${code}`, {})

    return true
  } catch (error) {
    return false
  }
}
