import { Accordion } from 'react-bootstrap'
import Page from '../page'
import React, { useState } from 'react'

import Header from './header'
import Navbar from './navbar'
import Footer from './footer'

type Props = {
  children: React.ReactNode
}

const Wrapper: React.FC<Props> = ({ children }) => {
  const [activeKey, setActiveKey] = useState('0')

  return (
    <Page>
      <Page.Main>
        <Accordion activeKey={activeKey}>
          <Header activeKey={activeKey} setActiveKey={setActiveKey} />
          <Navbar setActiveKey={setActiveKey} />
        </Accordion>
        {children}
      </Page.Main>
      <Footer />
    </Page>
  )
}

export default Wrapper
