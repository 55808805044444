import { ZoomMeeting, ZoomMeetingInfo } from '../../types'
import axios from 'axios'

export const createMeeting = async (): Promise<boolean> => {
  try {
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/zoom/meetings`)

    return true
  } catch (error) {
    return false
  }
}

export const getMeeting = async (meetingId: number): Promise<ZoomMeeting | null> => {
  try {
    const { data } = await axios.get<{ meeting: ZoomMeeting }>(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/zoom/meetings/${meetingId}`
    )

    return data.meeting
  } catch (error) {
    return null
  }
}

export const getMeetings = async (): Promise<ZoomMeetingInfo[]> => {
  try {
    const { data } = await axios.get<{ meetings: ZoomMeetingInfo[] }>(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/zoom/meetings`
    )

    return data.meetings
  } catch (error) {
    return []
  }
}

export const sendToken = async (token: string): Promise<boolean> => {
  try {
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/zoom/token`, { token })

    return true
  } catch (error) {
    return false
  }
}

export const postAuthCode = async (code: string): Promise<boolean> => {
  try {
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/zoom/${code}`, {})

    return true
  } catch (error) {
    return false
  }
}
