import { ProductList } from '../../types'
import axios from 'axios'

const API = process.env.REACT_APP_API_ENDPOINT

export const addProductList = async (productList: { name: string }): Promise<ProductList | false> => {
  try {
    const { data } = await axios.post<{ productList: ProductList }>(`${API}/api/v1/productLists`, productList)

    return data.productList
  } catch (error) {
    return false
  }
}

export const deleteProductList = async (productListId: string): Promise<boolean> => {
  try {
    await axios.delete(`${API}/api/v1/productLists/${productListId}`)

    return true
  } catch (error) {
    return false
  }
}

export const getProductLists = async (): Promise<ProductList[]> => {
  try {
    const { data } = await axios.get<{ productLists: ProductList[] }>(`${API}/api/v1/productLists`)

    return data.productLists
  } catch (error) {
    return []
  }
}

export const updateProductList = async (
  productListId: string,
  productList: ProductList
): Promise<ProductList | false> => {
  try {
    const { data } = await axios.put<{ productList: ProductList }>(
      `${API}/api/v1/productLists/${productListId}`,
      productList
    )

    return data.productList
  } catch (error) {
    return false
  }
}
