import { updateToken } from '../../redux/actions/authActions'
import API from '../../api'
import store from '../../redux/store'

const getBearer = async (): Promise<string> => {
  const { token, refreshToken, expiresAt } = store.getState().auth

  if (!token || !refreshToken || !expiresAt) return 'Bearer '

  if (Date.now() - expiresAt > 0) {
    const newTokens = await API.Auth.getNewToken(refreshToken)

    if (!newTokens) return 'Bearer '

    const { token, expiresAt } = newTokens

    store.dispatch(updateToken(token, expiresAt))

    return `Bearer ${token}`
  }

  return `Bearer ${token}`
}

export default getBearer
