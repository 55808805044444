import { Product, ProductList } from '..'

export enum ProductListsActionTypes {
  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_PRODUCT_LIST = 'ADD_PRODUCT_LIST',
  CLEAR_PRODUCTS_ERROR = 'CLEAR_PRODUCTS_ERROR',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  DELETE_PRODUCT_LIST = 'DELETE_PRODUCT_LIST',
  PRODUCTS_ERROR = 'PRODUCTS_ERROR',
  SET_PRODUCT_LISTS_LOADING = 'SET_PRODUCT_LISTS_LOADING',
  SET_PRODUCTLISTS = 'SET_PRODUCTLISTS',
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  SET_SELECTED_PRODUCTLIST_INDEX = 'SET_SELECTED_PRODUCTLIST_INDEX',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST'
}

type AddProduct = {
  type: ProductListsActionTypes.ADD_PRODUCT
  payload: { productListId: string; newProduct: Product }
}

type AddProductList = {
  type: ProductListsActionTypes.ADD_PRODUCT_LIST
  payload: ProductList
}

type ClearProductListsError = {
  type: ProductListsActionTypes.CLEAR_PRODUCTS_ERROR
}

type DeleteProduct = {
  type: ProductListsActionTypes.DELETE_PRODUCT
  payload: { productListId: string; productId: string }
}

type DeleteProductList = {
  type: ProductListsActionTypes.DELETE_PRODUCT_LIST
  payload: string
}

type ProductError = {
  type: ProductListsActionTypes.PRODUCTS_ERROR
  payload: string
}

type SetProductList = {
  type: ProductListsActionTypes.SET_PRODUCTLISTS
  payload: ProductList[]
}

type SetLoading = {
  type: ProductListsActionTypes.SET_PRODUCT_LISTS_LOADING
  payload: string
}

type SetSelectedProduct = {
  type: ProductListsActionTypes.SET_SELECTED_PRODUCT
  payload: Product | null
}

type SetSelectedProductListIndex = {
  type: ProductListsActionTypes.SET_SELECTED_PRODUCTLIST_INDEX
  payload: number
}

type UpdateProduct = {
  type: ProductListsActionTypes.UPDATE_PRODUCT
  payload: { productListId: string; updatedProduct: Product }
}

type UpdateProductList = {
  type: ProductListsActionTypes.UPDATE_PRODUCT_LIST
  payload: ProductList
}
export type ProductListsAction =
  | AddProduct
  | AddProductList
  | ClearProductListsError
  | DeleteProduct
  | DeleteProductList
  | ProductError
  | SetProductList
  | SetLoading
  | SetSelectedProduct
  | SetSelectedProductListIndex
  | UpdateProduct
  | UpdateProductList
