import { AuthAction, AuthActionsTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'
import setLoading from './setLoading'

export const resetPassword = (
  actionCode: string,
  password: string
): ThunkAction<void, IGlobalState, unknown, AuthAction> => async dispatch => {
  dispatch(setLoading('resetPassword'))

  const tokens = await API.Auth.resetPassword(actionCode, password)

  if (tokens) {
    dispatch({
      type: AuthActionsTypes.PASSWORD_RESETED,
      payload: tokens
    })
  } else {
    dispatch({
      type: AuthActionsTypes.AUTH_ERROR,
      payload: 'reset password error'
    })

    setTimeout(() => dispatch({ type: AuthActionsTypes.CLEAR_ERRORS }), 5000)
  }
}
