import { ProductFormValues, NewProduct } from '../types'

export const newProduct = ({ stripePrice, stripeProduct }: ProductFormValues): NewProduct | null => {
  if (!stripePrice || !stripeProduct) return null

  const product: NewProduct = {
    stripePrice,
    stripeProduct
  }

  return product
}
