import { IGlobalState, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'

export const deleteProduct = (
  productListId: string,
  productId: string
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => async dispatch => {
  const success = await API.Products.deleteProduct(productListId, productId)

  if (success) {
    dispatch({
      type: ProductListsActionTypes.DELETE_PRODUCT,
      payload: { productListId, productId }
    })
  } else {
    dispatch({
      type: ProductListsActionTypes.PRODUCTS_ERROR,
      payload: 'deleteProduct error'
    })

    setTimeout(() => dispatch({ type: ProductListsActionTypes.CLEAR_PRODUCTS_ERROR }), 5000)
  }
}
