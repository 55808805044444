import { EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const setSelectedEventListIndex = (
  index: number
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => dispatch => {
  dispatch({
    type: EventListsActionTypes.SET_SELECTED_EVENTLIST_INDEX,
    payload: index
  })
}
