import { Button } from 'tabler-react'
import Modal from 'react-bootstrap/Modal'
import React from 'react'

type Props = {
  cancelButtonLabel: string
  confirmButtonLabel: string
  onCancel: () => void
  onConfirm: () => void
  title: string
  message: string
  show: boolean
  loading: boolean | string
}

const ConfirmationModal: React.FC<Props> = ({
  cancelButtonLabel,
  confirmButtonLabel,
  onCancel,
  onConfirm,
  title,
  message,
  show,
  loading
}) => {
  return (
    <Modal centered size='sm' show={show} backdrop='static'>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button type='button' color='' onClick={onCancel}>
          {cancelButtonLabel || 'Cancel'}
        </Button>
        <Button className='ml-auto' loading={loading} type='button' color='danger' onClick={onConfirm}>
          {confirmButtonLabel || 'Delete'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
