import PageSubTitle from './PageSubTitle'
import PageTitle from './PageTitle'
import React from 'react'

type Props = {
  children?: React.ReactNode
  options?: React.ReactNode
  subTitle?: string
  title?: string
}

const PageHeader: React.FC<Props> = ({ children, title, subTitle }) => (
  <div className='page-header'>
    {title && <PageTitle>{title}</PageTitle>}
    {subTitle && <PageSubTitle>{subTitle}</PageSubTitle>}
    {children}
  </div>
)

PageHeader.displayName = 'Page.Header'

export default PageHeader
