export * from './stripe'
export * from './env'
export * from './events'

export enum ActionCodeMode {
  resetPassword = 'resetPassword'
}

export enum CardMode {
  edit = 'edit'
}

export enum getPublishableKeyMode {
  event = 'event',
  product = 'product'
}

export enum PaymentSuccessMode {
  event = 'event',
  product = 'product'
}

export enum Role {
  admin = 'admin',
  user = 'user'
}

export enum Service {
  stripe = 'stripe',
  zoom = 'zoom'
}
