import { useSelector } from '../../hooks'
import React from 'react'
import ZoomModal from './zoomModal'

const RootModal: React.FC = () => {
  const modalType = useSelector(state => state.modals.modalType)

  switch (modalType) {
    case 'zoomModal':
      return <ZoomModal />
    default:
      return null
  }
}

export default RootModal
