import { Container, Row, Col, Image, Form } from 'react-bootstrap'
import { emailValidation } from './validation'
import { Formik } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import { sendPasswordResetEmail } from '../../redux/actions/authActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../hooks'
import FormCard from '../../components/cards/formCard'
import Logo from '../../assets/images/linkzyLogoWithText.svg'
import Page from '../../layout/page'
import React, { useState, useEffect } from 'react'

const ForgotPassword: React.FC<RouteComponentProps> = ({ history }) => {
  const { error, isAuthenticated, loading, resetPasswordEmailSent } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
    // eslint-disable-next-line
  }, [isAuthenticated, error])

  const [isDirty, setDirty] = useState(false)

  return (
    <Formik
      validationSchema={null}
      validateOnChange={isDirty}
      onSubmit={({ email }) => {
        dispatch(sendPasswordResetEmail(email))
      }}
      initialValues={{ email: '' }}
      validate={values => {
        setDirty(true)
        emailValidation(values)
      }}
    >
      {({ handleSubmit, handleChange, errors }) => (
        <Page>
          <div className='page-single'>
            <Container>
              <Row>
                <Col className='col-login mx-auto'>
                  <div className='text-center mb-6'>
                    <Image className='h-8' src={Logo} />
                  </div>
                  <FormCard
                    title='Forgot Password'
                    buttonText='Email me'
                    onSubmit={handleSubmit}
                    loading={loading}
                    success={resetPasswordEmailSent}
                    successText='Email sent'
                  >
                    <p className='text-muted'>
                      Enter your email below and we&apos;ll send you a link to reset your password.
                    </p>
                    <Form.Group controlId='email'>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        className={errors.email ? 'state-invalid' : ''}
                        type='email'
                        placeholder='Enter email'
                        name='email'
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                  </FormCard>
                </Col>
              </Row>
            </Container>
          </div>
        </Page>
      )}
    </Formik>
  )
}

export default ForgotPassword
