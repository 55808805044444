import { Button, Form } from 'tabler-react'
import { capitalize } from '../../../utils/strings'
import { Col, Row } from 'react-bootstrap'
import { defaultValues, months, hours, minutes } from './defaultValues'
import { Event, EventFormErrors, EventFormValues, NewEvent, ZoomMeetingInfo } from '../../../types'
import { EventStatus, PaymentSolution, RegistrationsStatus, Service, StreamingSolution } from '../../../enums'
import { getFormatedStripePrice } from '../../../utils/stripe'
import { getTime, getHours, getMinutes, toDate, differenceInMinutes } from 'date-fns'
import { newEvent } from '../../../models'
import { numberOnlyRegex } from '../../../utils/regex'
import { showModal } from '../../../redux/actions/modalsActions'
import { StripePrice, StripeProduct } from '../../../types/stripe'
import { StripePriceType } from '../../../enums/stripe'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import API from '../../../api'
import React, { ChangeEvent, useEffect, useState } from 'react'
import validation from './validation'

type Props = {
  event?: Event | null
  onDelete?: () => void
  onSubmit: (event: NewEvent) => void
}

const EventForm: React.FC<Props> = ({ event, onDelete, onSubmit }) => {
  const dispatch = useDispatch()
  const services = useSelector(state => state.auth.user?.services)
  const [errors, setErrors] = useState<EventFormErrors>({})
  const [isDirty, setIsDirty] = useState(false)
  const [stripeProducts, setStripeProducts] = useState<StripeProduct[]>([])
  const [stripePrices, setStripePrices] = useState<StripePrice[]>([])
  const [zoomMeetings, setZoomMeetings] = useState<ZoomMeetingInfo[]>([])
  const [values, setValues] = useState<EventFormValues>(
    event
      ? {
          ...defaultValues,
          ...event,
          date: event.date,
          hour: getHours(event.date),
          minute: getMinutes(event.date),
          openingTime: event.openingTime ? differenceInMinutes(event.date, event.openingTime) : undefined,
          zoomMeetingId:
            event.streamingSolution === StreamingSolution.zoom && event.zoomMeeting ? event.zoomMeeting.id : undefined
        }
      : defaultValues
  )

  useEffect(() => {
    values.paymentSolution === PaymentSolution.stripe && getStripeProducts()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    values.paymentSolution === PaymentSolution.stripe && getStripeProducts()
    // eslint-disable-next-line
  }, [values.paymentSolution])

  useEffect(() => {
    values.streamingSolution === StreamingSolution.zoom && getZoomMeetings()
    // eslint-disable-next-line
  }, [values.streamingSolution])

  const getStripeProducts = async () => {
    const [products, prices] = await Promise.all([API.Stripe.getProducts(), API.Stripe.getPrices()])

    products && setStripeProducts(products)
    prices && setStripePrices(prices)

    products.length &&
      setValues({
        ...values,
        stripeProduct: values.stripeProduct || products[0],
        stripePrice:
          values.stripePrice ||
          prices.filter(price => price.product === products[0].id && price.type === StripePriceType.one_time)[0]
      })
  }

  const getZoomMeetings = async () => {
    const meetings = await API.Zoom.getMeetings()

    setZoomMeetings(meetings)

    if (meetings.length) {
      const linkedMeeting = meetings.find(meeting => meeting.id === values.zoomMeetingId)

      setValues({
        ...values,
        zoomMeetingId: linkedMeeting ? linkedMeeting.id : values.zoomMeetingId ? undefined : meetings[0].id
      })
    } else {
      setValues({ ...values, zoomMeetingId: undefined })
    }
  }

  const handleSubmit = async () => {
    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    const event = await newEvent(values)

    event && onSubmit(event)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newValues: EventFormValues = { ...values, [name]: value }

    setValues(newValues)

    isDirty && setErrors(validation(newValues)[1])
  }

  return services ? (
    <>
      <Row>
        <Col md={6}>
          <Form.Group label='Name'>
            <Form.Input
              type='text'
              name='name'
              value={values.name || ''}
              invalid={errors.name}
              cross={errors.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group label='Description (optional)'>
            <Form.Input
              type='text'
              name='description'
              value={values.description || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group label='Date'>
            <Form.DatePicker
              name='date'
              defaultDate={toDate(values.date)}
              format='dd/mm/yyyy'
              maxYear={2050}
              minYear={2020}
              monthLabels={months}
              values={toDate(values.date)}
              onChange={(date: Date) => setValues({ ...values, date: getTime(date) })}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group label='Time'>
            <Form.InputGroup>
              <Form.Select name='hour' value={values.hour} onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}>
                {hours.map(hour => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                name='minute'
                value={values.minute}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
              >
                {minutes.map(minute => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </Form.Select>
            </Form.InputGroup>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group label='Event opening (optional)'>
            <Form.InputGroup>
              <Form.InputGroupPrepend>
                <Form.InputGroupText>Open the event</Form.InputGroupText>
              </Form.InputGroupPrepend>
              <Form.Input
                type='text'
                name='openingTime'
                value={values.openingTime || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => numberOnlyRegex.test(e.target.value) && onChange(e)}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText>minutes before the beginning</Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group label='Payment solution'>
            {services.includes(Service.stripe) ? (
              <Form.Switch
                name='payingSolution'
                label='Stripe'
                checked={values.paymentSolution === 'stripe'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.target.checked &&
                    setValues({
                      ...values,
                      paymentSolution: e.target.checked ? PaymentSolution.stripe : undefined
                    })

                  !e.target.checked &&
                    setValues({
                      ...values,
                      paymentSolution: undefined,
                      registrationsStatus: undefined,
                      stripeProduct: undefined,
                      stripePrice: undefined
                    })
                }}
              />
            ) : (
              <a
                href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_STRIPE_REDIRECT_URI}`}
              >
                <Button size='sm' color='primary'>
                  Link Stripe
                </Button>
              </a>
            )}
          </Form.Group>
        </Col>
      </Row>
      {values.paymentSolution === 'stripe' && (
        <Row>
          <Col>
            <Form.Group label='Product'>
              <Form.Select
                value={values.stripeProduct?.name}
                onChange={(e: ChangeEvent<HTMLOptionsCollection>) => {
                  setValues({
                    ...values,
                    stripeProduct: stripeProducts[e.target.selectedIndex],
                    stripePrice: stripePrices.filter(
                      price => price.product === stripeProducts[e.target.selectedIndex].id && price.type === 'one_time'
                    )[0]
                  })
                }}
                invalid={errors.stripeProduct}
                cross={errors.stripeProduct}
              >
                {stripeProducts.map(product => (
                  <option key={product.name} value={product.name}>
                    {product.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group label='Price'>
              <Form.Select
                value={values.stripePrice ? getFormatedStripePrice(values.stripePrice) : ''}
                onChange={(e: ChangeEvent<HTMLOptionsCollection>) => {
                  setValues({
                    ...values,
                    stripePrice: stripePrices.filter(
                      price => price.product === values.stripeProduct?.id && price.type === 'one_time'
                    )[e.target.selectedIndex]
                  })
                }}
                invalid={errors.stripePrice}
                cross={errors.stripePrice}
              >
                {stripePrices
                  .filter(price => price.product === values.stripeProduct?.id && price.type === 'one_time')
                  .map(price => (
                    <option key={price.id} value={getFormatedStripePrice(price)}>
                      {getFormatedStripePrice(price)}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Form.Group label='Streaming solution'>
            <Row>
              <Col xs={6} md={3} lg={2}>
                <Form.Switch
                  name='streamingSolution'
                  label='Link'
                  checked={values.streamingSolution === StreamingSolution.link}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.checked &&
                      setValues({
                        ...values,
                        streamingSolution: StreamingSolution.link
                      })
                  }}
                />
              </Col>
              <Col xs={6} md={3} lg={2}>
                <Form.Switch
                  name='streamingSolution'
                  label='Protected link'
                  checked={values.streamingSolution === StreamingSolution.protectedLink}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.checked &&
                      setValues({
                        ...values,
                        streamingSolution: StreamingSolution.protectedLink
                      })
                  }}
                />
              </Col>
              <Col xs={6} md={3} lg={2}>
                <Form.Switch
                  name='streamingSolution'
                  label='Zoom'
                  checked={values.streamingSolution === StreamingSolution.zoom}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (services.includes(Service.zoom)) {
                      e.target.checked &&
                        setValues({
                          ...values,
                          streamingSolution: StreamingSolution.zoom
                        })
                    } else dispatch(showModal({ type: 'zoomModal' }))
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      {[StreamingSolution.link, StreamingSolution.protectedLink].includes(values.streamingSolution) && (
        <Row>
          <Col xs={12}>
            <Form.Group label='Streaming link'>
              <Form.Input
                type='text'
                name='zoomLink'
                value={values.zoomLink || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
                invalid={errors.zoomLink}
                cross={errors.zoomLink}
              />
            </Form.Group>
          </Col>
          {values.streamingSolution === StreamingSolution.protectedLink && (
            <Col xs={12}>
              <Form.Group label='Link password'>
                <Form.Input
                  type='text'
                  name='streamingLinkPassword'
                  value={values.streamingLinkPassword || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
                  invalid={errors.streamingLinkPassword}
                  cross={errors.streamingLinkPassword}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      )}
      {values.streamingSolution === StreamingSolution.zoom && (
        <Row>
          <Col>
            <Form.Group label='Zoom meeting'>
              <Form.Select
                value={zoomMeetings.find(meeting => meeting.id === values.zoomMeetingId)?.topic || ''}
                onChange={(e: ChangeEvent<HTMLOptionsCollection>) => {
                  setValues({
                    ...values,
                    zoomMeetingId: zoomMeetings[e.target.selectedIndex].id
                  })
                }}
                invalid={errors.zoomMeetingId}
                cross={errors.zoomMeetingId}
              >
                {!zoomMeetings.some(meeting => meeting.id === values.zoomMeetingId) ? (
                  <option key='noMeeting'></option>
                ) : null}
                {zoomMeetings.map(meeting => (
                  <option key={meeting.id} value={meeting.topic}>
                    {meeting.topic}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={6} md={3} lg={2}>
          <Form.Group label='Event status'>
            <Form.Switch
              name='status'
              label={values.status === 'open' ? 'Live' : capitalize(values.status)}
              checked={values.status === 'open'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                e.target.checked && setValues({ ...values, status: EventStatus.open })
                !e.target.checked && setValues({ ...values, status: EventStatus.closed })
              }}
            />
          </Form.Group>
        </Col>
        {values.paymentSolution && (
          <Col xs={6} md={3} lg={2}>
            <Form.Group label='Registrations status'>
              <Form.Switch
                name='registrationsStatus'
                label={values.registrationsStatus ? capitalize(values.registrationsStatus) : ''}
                checked={values.registrationsStatus === 'open'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.target.checked && setValues({ ...values, registrationsStatus: RegistrationsStatus.open })
                  !e.target.checked && setValues({ ...values, registrationsStatus: RegistrationsStatus.closed })
                }}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          {event ? (
            <Button color='danger' onClick={onDelete}>
              Delete
            </Button>
          ) : null}
          <Button className='float-right' color='primary' onClick={handleSubmit}>
            {event ? 'Update event' : 'Add event'}
          </Button>
        </Col>
      </Row>
    </>
  ) : null
}

export default EventForm
