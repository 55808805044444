import { Button, Icon } from 'tabler-react'
import { Container } from 'react-bootstrap'
import Page from '../../layout/page'
import React from 'react'

type Props = {
  title: string
  subtitle: string
  details?: string
  buttonText?: string
  buttonAction?: () => void
}

const ErrorPage: React.FC<Props> = ({
  title = 'Error',
  subtitle = 'Sorry an unkown error occurred',
  details,
  buttonText,
  buttonAction
}) => {
  const defaultAction = () => {
    window.history.back()
  }

  return (
    <Page className='text-center'>
      <Container>
        <h1 className='display-1 text-muted mb-5'>{title}</h1>
        <h2>{subtitle}</h2>
        {details && <h4 className='text-muted font-weight-normal mb-7'>{details}</h4>}
        {buttonText && (
          <Button onClick={buttonAction || defaultAction} className='btn-primary'>
            {!buttonAction && <Icon className='mr-2' name='arrow-left' />}
            {buttonText}
          </Button>
        )}
      </Container>
    </Page>
  )
}

export default ErrorPage
