import { ModalsAction, ModalsActionTypes, ModalsReducer } from '../../types'
import initialState from '../initialState'

const modalsReducer = (state = initialState.modals, action: ModalsAction): ModalsReducer => {
  switch (action.type) {
    case ModalsActionTypes.SHOW_MODAL:
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps
      }
    case ModalsActionTypes.HIDE_MODAL:
      return initialState.modals
    case ModalsActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        show: !state.show
      }
    default:
      return {
        ...state
      }
  }
}

export default modalsReducer
