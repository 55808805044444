import { IGlobalState, ProductList, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const setProductLists = (
  productLists: ProductList[]
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => dispatch => {
  dispatch({
    type: ProductListsActionTypes.SET_PRODUCTLISTS,
    payload: productLists
  })
}
