import { ModalsAction, ModalsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const showModal = (props: {
  type: string
  props?: Record<string, unknown>
}): ThunkAction<void, IGlobalState, unknown, ModalsAction> => dispatch => {
  dispatch({
    type: ModalsActionTypes.SHOW_MODAL,
    modalType: props.type,
    modalProps: props.props
  })
}
