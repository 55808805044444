import { Button, Form } from 'tabler-react'
import { Col, Row } from 'react-bootstrap'
import { defaultValues } from './defaultValues'
import { SocialMedia } from '../../../types'
import { useSelector } from '../../../hooks'
import { updateSocialMedia } from '../../../redux/actions/authActions'
import { useDispatch } from 'react-redux'
import API from '../../../api'
import React, { ChangeEvent, useState } from 'react'

const SocialMediaForm: React.FC = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const socialMedia = useSelector(state => state.auth.user?.socialMedia)
  const [values, setValues] = useState<SocialMedia>(
    socialMedia
      ? {
          ...defaultValues,
          ...socialMedia
        }
      : defaultValues
  )

  const handleSubmit = async () => {
    setLoading(true)

    const socialMedia = await API.User.updateSocialMedia(values)

    if (socialMedia) {
      dispatch(updateSocialMedia(socialMedia))
    } else {
      setError(true)

      setTimeout(() => setError(false), 3000)
    }

    setLoading(false)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    const newValues: SocialMedia = { ...values, [name]: value }

    setValues(newValues)
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group label='Facebook'>
            <Form.Input
              type='text'
              name='facebook'
              value={values.facebook || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group label='Instagram'>
            <Form.Input
              type='text'
              name='instagram'
              value={values.instagram || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group label='LinkedIn'>
            <Form.Input
              type='text'
              name='linkedIn'
              value={values.linkedIn || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group label='Twitter'>
            <Form.Input
              type='text'
              name='twitter'
              value={values.twitter || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className='float-right'
            color={error ? 'danger' : 'primary'}
            loading={loading}
            disabled={loading || error}
            onClick={handleSubmit}
          >
            Update social medias
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SocialMediaForm
