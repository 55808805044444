import { Col, Container, Image, Row } from 'react-bootstrap'
import { Form } from 'tabler-react'
import { Formik } from 'formik'
import { Link, RouteComponentProps } from 'react-router-dom'
import { login, loadUser } from '../../redux/actions/authActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../hooks'
import { validation, schema } from './validation'
import FormCard from '../../components/cards/formCard'
import Logo from '../../assets/images/linkzyLogoWithText.svg'
import Page from '../../layout/page'
import React, { useState, useEffect, Fragment } from 'react'

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const { error, isAuthenticated, loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
    if (!isAuthenticated && localStorage.token) {
      dispatch(loadUser())
    }
    // eslint-disable-next-line
  }, [isAuthenticated, error])

  const [isDirty, setDirty] = useState(false)

  return (
    <Fragment>
      <Formik
        validationSchema={schema}
        validateOnChange={isDirty}
        onSubmit={({ email, password }) => {
          dispatch(login(email, password))
        }}
        initialValues={{ email: '', password: '' }}
        validate={values => {
          setDirty(true)
          return validation(values)
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Page>
            <div className='page-single'>
              <Container>
                <Row>
                  <Col className='col-login mx-auto'>
                    <div className='text-center mb-6'>
                      <Image src={Logo} />
                    </div>
                    <FormCard
                      title='Login to your account'
                      buttonText='Login'
                      onSubmit={handleSubmit}
                      loading={loading}
                      dual
                      stacked
                    >
                      <Form.Group label='Email'>
                        <Form.Input
                          type='email'
                          placeholder='Enter email'
                          name='email'
                          value={values.email}
                          onChange={handleChange}
                          error={errors.email}
                          cross={errors.email}
                        />
                      </Form.Group>
                      <Form.Group label='Password'>
                        <Form.Input
                          type='password'
                          placeholder='Enter password'
                          name='password'
                          value={values.password}
                          onChange={handleChange}
                          error={errors.password}
                          cross={errors.password}
                        />
                      </Form.Group>
                    </FormCard>
                    <div className='text-right'>
                      <small>
                        <Link to='/forgotPassword'>Forgot password?</Link>
                      </small>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Page>
        )}
      </Formik>
    </Fragment>
  )
}

export default Login
