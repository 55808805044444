import { IGlobalState, NewProduct, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'

export const addProduct = (
  productListId: string,
  product: NewProduct
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => async dispatch => {
  const newProduct = await API.Products.addProduct(productListId, product)

  if (newProduct) {
    dispatch({
      type: ProductListsActionTypes.ADD_PRODUCT,
      payload: { productListId, newProduct }
    })
  } else {
    dispatch({
      type: ProductListsActionTypes.PRODUCTS_ERROR,
      payload: 'addProduct error'
    })

    setTimeout(() => dispatch({ type: ProductListsActionTypes.CLEAR_PRODUCTS_ERROR }), 5000)
  }
}
