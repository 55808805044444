import { EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const deleteEventList = (
  eventListId: string
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => async dispatch => {
  dispatch({
    type: EventListsActionTypes.DELETE_EVENT_LIST,
    payload: eventListId
  })
}
