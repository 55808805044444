import { IGlobalState, Product, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'

export const updateProduct = (
  productListId: string,
  product: Product
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => async dispatch => {
  const updatedProduct = await API.Products.updateProduct(productListId, product)

  if (updatedProduct) {
    dispatch({
      type: ProductListsActionTypes.UPDATE_PRODUCT,
      payload: { productListId, updatedProduct }
    })
  } else {
    dispatch({
      type: ProductListsActionTypes.PRODUCTS_ERROR,
      payload: 'updateProduct error'
    })
  }
}
