import { EventListsAction, EventListsActionTypes, IGlobalState, NewEvent } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import API from '../../../api'

export const addEvent = (
  eventListId: string,
  event: NewEvent
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => async dispatch => {
  const newEvent = await API.Events.createEvent(eventListId, event)

  if (newEvent) {
    dispatch({
      type: EventListsActionTypes.ADD_EVENT,
      payload: { eventListId, newEvent }
    })
  } else {
    dispatch({
      type: EventListsActionTypes.EVENTS_ERROR,
      payload: 'addEvent error'
    })

    setTimeout(() => dispatch({ type: EventListsActionTypes.CLEAR_EVENTS_ERROR }), 5000)
  }
}
