import { default as AdminHome } from './admin/home'
import { default as UsersHome } from './users/home'
import { loadUser } from '../redux/actions/authActions'
import { Role } from '../enums'
import { Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from '../hooks'
import Event from './users/event'
import Product from './users/product'
import Products from './users/products'
import React, { Fragment, useEffect } from 'react'
import Redirect from './auth/oauth/redirect'
import Row from 'react-bootstrap/Row'
import Settings from './users/settings'
import Spinner from 'react-bootstrap/Spinner'
import Wrapper from '../layout/wrapper'

const Dashboard: React.FC = () => {
  const { loading, user } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user && !loading) {
      dispatch(loadUser())
    }
    // eslint-disable-next-line
  }, [user])

  const userRoutes = (
    <Fragment>
      <Wrapper>
        <Switch>
          <Route exact path='/' component={UsersHome} />
          <Route exact path='/event' component={Event} />
          <Route exact path='/product' component={Product} />
          <Route exact path='/products' component={Products} />
          <Route exact path='/settings' component={Settings} />
          <Route exact path='/redirect' component={Redirect} />
          <Route component={UsersHome} />
        </Switch>
      </Wrapper>
    </Fragment>
  )

  const adminRoutes = (
    <Fragment>
      <Wrapper>
        <Switch>
          <Route exact path='/' component={AdminHome} />
          <Route component={AdminHome} />
        </Switch>
      </Wrapper>
    </Fragment>
  )

  return !user ? (
    <Row className='justify-content-center align-items-center'>
      <Spinner animation='border' />
    </Row>
  ) : user.role === Role.admin ? (
    adminRoutes
  ) : user.role === Role.user ? (
    userRoutes
  ) : null
}

export default Dashboard
