import { Event, GetEventsResponse, GetEventUrlResponse, NewEvent, PublicEvent } from '../../types'
import axios from 'axios'

const API = process.env.REACT_APP_API_ENDPOINT

export const createEvent = async (eventListId: string, event: NewEvent): Promise<Event | false> => {
  try {
    const { data } = await axios.post<{ event: Event }>(`${API}/api/v1/events/${eventListId}`, event)

    return data.event
  } catch (error) {
    return false
  }
}

export const deleteEvent = async (eventListId: string, eventId: string): Promise<boolean> => {
  try {
    await axios.delete(`${API}/api/v1/events/${eventListId}/${eventId}`)

    return true
  } catch (error) {
    return false
  }
}

export const getEvents = async (eventListId: string): Promise<GetEventsResponse | false> => {
  try {
    const { data } = await axios.get<GetEventsResponse>(`${API}/api/v1/events/${eventListId}`)

    return data
  } catch (error) {
    return false
  }
}

export const getEventUrl = async (
  eventListId: string,
  event: PublicEvent,
  email: string
): Promise<GetEventUrlResponse | false> => {
  try {
    const { data } = await axios.post<GetEventUrlResponse>(
      `${API}/api/v1/events/registrations/${eventListId}/${event.id}`,
      {
        email
      }
    )

    return data
  } catch (error) {
    return false
  }
}

export const resetRegistrations = async (eventListId: string, eventId: string): Promise<boolean> => {
  try {
    await axios.delete(`${API}/api/v1/events/registrations/${eventListId}/${eventId}`)

    return true
  } catch (error) {
    return false
  }
}

export const updateEvent = async (eventListId: string, event: Event): Promise<Event | false> => {
  try {
    const { data } = await axios.put<{ event: Event }>(`${API}/api/v1/events/${eventListId}/${event.id}`, event)

    return data.event
  } catch (error) {
    return false
  }
}
