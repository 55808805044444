export const Env = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || '',
  CLIENT_ADDRESS: process.env.REACT_APP_CLIENT_ADDRESS || '',

  ZOOM_CLIENT_ID: process.env.REACT_APP_ZOOM_CLIENT_ID || '',
  ZOOM_REDIRECT_URI: process.env.REACT_APP_ZOOM_REDIRECT_URI || '',

  STRIPE_CLIENT_ID: process.env.REACT_APP_STRIPE_CLIENT_ID || '',
  STRIPE_REDIRECT_URI: process.env.REACT_APP_STRIPE_REDIRECT_URI || ''
}
