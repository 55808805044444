import { EventList, EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const setEventLists = (
  eventLists: EventList[]
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => dispatch => {
  dispatch({
    type: EventListsActionTypes.SET_EVENTLISTS,
    payload: eventLists
  })
}
