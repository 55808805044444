import { ProductListsAction, ProductListsActionTypes, ProductListsReducer } from '../../types'
import initialState from '../initialState'

const productListsReducer = (state = initialState.productLists, action: ProductListsAction): ProductListsReducer => {
  switch (action.type) {
    case ProductListsActionTypes.ADD_PRODUCT_LIST:
      return {
        ...state,
        productLists: [...state.productLists, action.payload]
      }
    case ProductListsActionTypes.ADD_PRODUCT: {
      const { productListId, newProduct } = action.payload

      return {
        ...state,
        productLists: state.productLists.map(productList =>
          productList.id === productListId
            ? { ...productList, products: [...productList.products, newProduct] }
            : productList
        )
      }
    }
    case ProductListsActionTypes.DELETE_PRODUCT: {
      const { productListId, productId } = action.payload

      return {
        ...state,
        productLists: state.productLists.map(productList =>
          productList.id === productListId
            ? { ...productList, products: productList.products.filter(product => product.id !== productId) }
            : productList
        )
      }
    }
    case ProductListsActionTypes.DELETE_PRODUCT_LIST:
      return {
        ...state,
        productLists: state.productLists.filter(productList => productList.id !== action.payload)
      }
    case ProductListsActionTypes.UPDATE_PRODUCT: {
      const { productListId, updatedProduct } = action.payload

      return {
        ...state,
        productLists: state.productLists.map(productList =>
          productList.id === productListId
            ? {
                ...productList,
                products: productList.products.map(product =>
                  product.id === updatedProduct.id ? updatedProduct : product
                )
              }
            : productList
        )
      }
    }
    case ProductListsActionTypes.UPDATE_PRODUCT_LIST:
      return {
        ...state,
        productLists: state.productLists.map(productList =>
          productList.id === action.payload.id ? { ...productList, name: action.payload.name } : productList
        )
      }
    case ProductListsActionTypes.CLEAR_PRODUCTS_ERROR:
      return {
        ...state,
        error: null
      }
    case ProductListsActionTypes.SET_PRODUCTLISTS:
      return {
        ...state,
        productLists: action.payload
      }
    case ProductListsActionTypes.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload
      }
    case ProductListsActionTypes.SET_SELECTED_PRODUCTLIST_INDEX:
      return {
        ...state,
        selectedProductListIndex: action.payload
      }
    case ProductListsActionTypes.SET_PRODUCT_LISTS_LOADING:
      return {
        ...state,
        loading: action.payload ?? true
      }
    case ProductListsActionTypes.PRODUCTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return {
        ...state
      }
  }
}

export default productListsReducer
