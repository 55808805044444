import { Loader } from 'tabler-react'
import { loadUser } from '../../redux/actions/authActions'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../hooks'
import React from 'react'
import Row from 'react-bootstrap/Row'

type Props = {
  component: React.ElementType
}

const PrivateRoute: React.FC<Props & RouteProps> = ({ component: Component, ...rest }) => {
  const { isAuthenticated, user } = useSelector(state => state.auth)
  let { loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  if (!user && localStorage.token && !loading) {
    loading = true
    dispatch(loadUser())
  }

  return loading ? (
    <Row className='h-100 justify-content-center align-items-center'>
      <Loader />
    </Row>
  ) : (
    <Route {...rest} render={props => (!isAuthenticated ? <Redirect to='/Login' /> : <Component {...props} />)} />
  )
}

export default PrivateRoute
