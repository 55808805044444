import { Button, Form } from 'tabler-react'
import { Col, Row } from 'react-bootstrap'
import { getPublishableKeyMode } from '../../../enums'
import { loadStripe } from '@stripe/stripe-js'
import { PublicEvent, RegistrationFormErrors } from '../../../types'
import API from '../../../api'
import defaultValues from './defaultValues'
import Modal from 'react-bootstrap/Modal'
import React, { ChangeEvent, useState } from 'react'
import validation from './validation'

type Props = {
  event: PublicEvent
  eventListId: string
  onHide: () => void
  show: boolean
}

const RegistrationModal: React.FC<Props> = ({ event, eventListId, onHide, show }) => {
  const [errors, setErrors] = useState<RegistrationFormErrors>({})
  const [loading, setLoading] = useState(false)
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [values, setValues] = useState(defaultValues)

  const goToStripePayment = async (sessionId: string) => {
    setLoading(true)

    const publishableKey = await API.Stripe.getPublishableKey(eventListId, getPublishableKeyMode.event)
    if (!publishableKey) return

    const stripe = await loadStripe(publishableKey)

    stripe &&
      stripe.redirectToCheckout({
        sessionId
      })

    setLoading(false)
  }

  const onSubmit = async () => {
    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    setLoading(true)

    const eventInfo = await API.Events.getEventUrl(eventListId, event, values.email)

    if (eventInfo) {
      const { eventUrl, isRegistered, sessionId } = eventInfo

      if (eventUrl) window.location.href = eventUrl
      else if (isRegistered) {
        setIsAlreadyRegistered(true)
      } else {
        sessionId && goToStripePayment(sessionId)
      }
    } else {
      event.registrationsStatus === 'closed'
        ? setErrors({ ...errors, error: 'Registrations are closed' })
        : setErrors({ ...errors, error: 'A problem occurred, try again later or contact the event host' })
    }

    setTimeout(() => onHide(), 3000)

    setLoading(false)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newValues = { ...values, [name]: value }

    setValues(newValues)

    isDirty && setErrors(validation(newValues)[1])
  }

  return (
    <Modal centered size='sm' onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>{event.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            {errors.error ? (
              <h3>{errors.error}</h3>
            ) : isAlreadyRegistered ? (
              <h3>You are already registered to this event</h3>
            ) : (
              <Form.Group label='Enter your email'>
                <Form.Input
                  type='text'
                  name='email'
                  value={values.email}
                  disabled={loading}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
                  error={errors.email}
                  cross={errors.email}
                />
              </Form.Group>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='ml-auto'
          loading={loading}
          disabled={loading || errors.error || isAlreadyRegistered}
          type='button'
          color='primary'
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RegistrationModal
