import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

const Footer: React.FC = () => (
  <div className='footer'>
    <Container>
      <Row>
        <Col></Col>
      </Row>
    </Container>
  </div>
)

export default Footer
