import { Button } from 'tabler-react'
import { Col, Row } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom'
import { Service } from '../../../enums'
import { showModal } from '../../../redux/actions/modalsActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import Page from '../../../layout/page'
import React from 'react'
import SocialMediaForm from '../../../components/forms/socialMediaForm'

const Event: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const services = useSelector(state => state.auth.user?.services)

  return (
    <Page.Content title='Settings'>
      <h3>Social media</h3>
      <Row className='mb-5'>
        <Col>
          <SocialMediaForm />
        </Col>
      </Row>

      <h3>Zoom</h3>
      <Row>
        <Col>
          <Button color='primary' onClick={() => dispatch(showModal({ type: 'zoomModal' }))}>
            {services && services.includes(Service.zoom) ? 'Update Zoom token' : 'Link Zoom account'}
          </Button>
        </Col>
      </Row>
    </Page.Content>
  )
}

export default Event
