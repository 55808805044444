import { AuthAction, AuthActionsTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'
import { Service } from '../../../enums'

export const addService = (
  service: Service
): ThunkAction<void, IGlobalState, unknown, AuthAction> => async dispatch => {
  dispatch({
    type: AuthActionsTypes.ADD_SERVICE,
    payload: service
  })
}
