import { IGlobalState, ProductListsAction, ProductListsActionTypes } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const setSelectedProductListIndex = (
  index: number
): ThunkAction<void, IGlobalState, unknown, ProductListsAction> => dispatch => {
  dispatch({
    type: ProductListsActionTypes.SET_SELECTED_PRODUCTLIST_INDEX,
    payload: index
  })
}
