import { EventList, EventListsAction, EventListsActionTypes, IGlobalState } from '../../../types'
import { ThunkAction } from 'redux-thunk'

export const updateEventList = (
  updatedEventList: EventList
): ThunkAction<void, IGlobalState, unknown, EventListsAction> => async dispatch => {
  dispatch({
    type: EventListsActionTypes.UPDATE_EVENT_LIST,
    payload: updatedEventList
  })
}
