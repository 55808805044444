import { ProductFormErrors, ProductFormValues } from '../../../types'

const validation = (values: ProductFormValues): [boolean, ProductFormErrors] => {
  const errors: ProductFormErrors = {}

  if (!values.stripePrice) errors.stripePrice = 'Price required'
  if (!values.stripeProduct) errors.stripeProduct = 'Product required'

  return [Object.keys(errors).length === 0, errors]
}

export default validation
