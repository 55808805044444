// prettier-ignore
import { addProductList, deleteProductList, setSelectedProductListIndex, updateProductList } from '../../../redux/actions/productListsActions'
import { Button, Form } from 'tabler-react'
import { Col, Row, Modal } from 'react-bootstrap'
import { ProductList, ProductListFormErrors, ProductListFormValues } from '../../../types'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import API from '../../../api'
import defaultValues from './defaultValues'
import initialState from '../../../redux/initialState'
import React, { ChangeEvent, useState } from 'react'
import validation from './validation'

type Props = {
  productList: ProductList | null
  show: boolean | string
  setShowModal: (show: '' | 'add' | 'edit' | false) => void
}

const ProductListModal: React.FC<Props> = ({ productList, show, setShowModal }) => {
  const { loading } = useSelector(state => state.productLists)
  const [errors, setErrors] = useState<ProductListFormErrors>({})
  const [isDirty, setIsDirty] = useState(false)
  const [values, setValues] = useState<ProductListFormValues>(
    productList ? { ...defaultValues, name: productList.name } : defaultValues
  )
  const dispatch = useDispatch()

  const onAdd = async () => {
    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    const newProductList = await API.ProductLists.addProductList(values)

    if (newProductList) {
      dispatch(addProductList(newProductList))

      setShowModal(false)
    }
  }

  const onDelete = async () => {
    if (!productList) return

    const success = await API.ProductLists.deleteProductList(productList.id)

    if (success) {
      dispatch(deleteProductList(productList.id))
      dispatch(setSelectedProductListIndex(initialState.productLists.selectedProductListIndex))

      setShowModal(false)
    }
  }

  const onUpdate = async () => {
    if (!productList) return

    !isDirty && setIsDirty(true)
    const [isValid, errors] = validation(values)
    setErrors(errors)

    if (!isValid) return

    const updatedProductList = await API.ProductLists.updateProductList(productList.id, { ...productList, ...values })

    if (updatedProductList) {
      dispatch(updateProductList(updatedProductList))

      setShowModal(false)
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    const newValues = { ...values, [name]: value }

    setValues(newValues)

    isDirty && setErrors(validation(newValues)[1])
  }

  return (
    <Modal centered size='sm' show={show} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>{productList ? 'Edit product list' : 'Add product list'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group label='Name'>
              <Form.Input
                type='text'
                name='name'
                value={values.name || ''}
                invalid={errors.name}
                cross={errors.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {Object.keys(values).length ? (
          <Row>
            <Col>
              <Button
                type='button'
                color='danger'
                loading={loading === 'deleteProductList'}
                disabled={loading}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Col>
          </Row>
        ) : null}
        <Col style={{ padding: '0px' }}>
          <Button
            className='float-right'
            type='button'
            color='primary'
            loading={loading === 'addProductList'}
            disabled={loading}
            onClick={() => (productList ? onUpdate() : onAdd())}
          >
            {productList ? 'Update' : 'Add'}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  )
}

export default ProductListModal
