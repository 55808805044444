import { Product, GetProductsResponse, NewProduct } from '../../types'
import axios from 'axios'

const API = process.env.REACT_APP_API_ENDPOINT

export const addProduct = async (productListId: string, product: NewProduct): Promise<Product | false> => {
  try {
    const { data } = await axios.post<{ product: Product }>(`${API}/api/v1/products/${productListId}`, product)

    return data.product
  } catch (error) {
    return false
  }
}

export const deleteProduct = async (productListId: string, productId: string): Promise<boolean> => {
  try {
    await axios.delete(`${API}/api/v1/products/${productListId}/${productId}`)

    return true
  } catch (error) {
    return false
  }
}

export const getCheckoutSessionId = async (productListId: string, productId: string): Promise<string | false> => {
  try {
    const { data } = await axios.get<{ sessionId: string }>(
      `${API}/api/v1/products/checkout/${productListId}/${productId}`
    )

    return data.sessionId
  } catch (error) {
    return false
  }
}

export const getProducts = async (productListId: string): Promise<GetProductsResponse | false> => {
  try {
    const { data } = await axios.get<GetProductsResponse>(`${API}/api/v1/products/${productListId}`)

    return data
  } catch (error) {
    return false
  }
}

export const updateProduct = async (productListId: string, product: Product): Promise<Product | false> => {
  try {
    const { data } = await axios.put<{ product: Product }>(
      `${API}/api/v1/products/${productListId}/${product.id}`,
      product
    )

    return data.product
  } catch (error) {
    return false
  }
}
