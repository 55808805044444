import { Container, Row, Col, Image, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { passwordValidation } from '../validation'
import { resetPassword } from '../../../redux/actions/authActions'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../hooks'
import FormCard from '../../../components/cards/formCard'
import Logo from '../../../assets/images/linkzyLogoWithText.svg'
import Page from '../../../layout/page'
import React, { useState, useEffect } from 'react'

type Props = {
  actionCode: string
  setRedirect: (value: boolean) => void
}

const ResetPassword: React.FC<Props> = ({ actionCode, setRedirect }) => {
  const { passwordReseted, loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (passwordReseted) {
      setTimeout(() => setRedirect(true), 500)
    }
    // eslint-disable-next-line
  }, [passwordReseted])

  const [isDirty, setDirty] = useState(false)

  return (
    <Formik
      validationSchema={null}
      validateOnChange={isDirty}
      onSubmit={({ password }) => {
        dispatch(resetPassword(actionCode, password))
      }}
      initialValues={{ password: '' }}
      validate={values => {
        setDirty(true)
        return passwordValidation(values)
      }}
    >
      {({ handleSubmit, handleChange, errors }) => (
        <Page>
          <div className='page-single'>
            <Container>
              <Row>
                <Col className='col-login mx-auto'>
                  <div className='text-center mb-6'>
                    <Image className='h-8' src={Logo} />
                  </div>
                  <FormCard
                    title={'Reset Password'}
                    buttonText='Save'
                    onSubmit={handleSubmit}
                    loading={loading}
                    success={passwordReseted}
                  >
                    <p className='text-muted'>Your password must be at least 16 characters</p>
                    <Form.Group controlId='password'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        className={errors.password ? 'state-invalid' : ''}
                        type='password'
                        placeholder='Enter password'
                        name='password'
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                  </FormCard>
                </Col>
              </Row>
            </Container>
          </div>
        </Page>
      )}
    </Formik>
  )
}

export default ResetPassword
