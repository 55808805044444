import { differenceInHours } from 'date-fns'
import { GetEventsResponse, PublicEvent } from '../../types'
import { Image } from 'react-bootstrap'
import { sortByDate } from '../../utils/arrays'
import { RouteComponentProps, useParams } from 'react-router-dom'
import API from '../../api'
import ErrorPage from '../errorPage'
import EventCard from '../../components/cards/eventCard'
import Loader from '../../components/loader'
import Logo from '../../assets/images/linkzyLogoWithText.svg'
import Page from '../../layout/page'
import React, { useEffect, useState } from 'react'
import RegistrationModal from '../../components/modals/registrationModal'

const EventList: React.FC<RouteComponentProps> = ({ history }) => {
  const id = useParams<{ id: string }>().id

  const [error, setError] = useState(false)
  const [eventList, setEventList] = useState<GetEventsResponse | null>(null)
  const [loading, setLoading] = useState('')
  const [modalState, setModalState] = useState<{ event: PublicEvent | null; show: boolean }>({
    event: null,
    show: false
  })
  const [pageLoading, setPageLoading] = useState(true)

  const gotToEvent = async (event: PublicEvent) => {
    setLoading(event.id)

    if (event.zoomLink) window.location.href = event.zoomLink

    setLoading('')
  }

  const retreiveEvents = async () => {
    const eventList = await API.Events.getEvents(id)

    if (eventList) setEventList(eventList)
    else setError(true)

    setPageLoading(false)
  }

  useEffect(() => {
    retreiveEvents()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='public_list'>
      {pageLoading ? (
        <Loader />
      ) : error ? (
        <ErrorPage title='Sorry' subtitle='This page does not exists' />
      ) : eventList ? (
        <>
          <div className='logo-container' onClick={() => history.push('/')}>
            <a className='logo header-brand' href='#'>
              <Image src={Logo} className='header-brand-img' />
            </a>
          </div>
          <Page.Content title={eventList.name}>
            {eventList.events
              .sort(sortByDate)
              .filter(event => differenceInHours(Date.now(), event.date) < 1)
              .map(event => (
                <EventCard
                  key={event.id}
                  event={event}
                  loading={loading}
                  onClick={() => (event.price ? setModalState({ show: true, event }) : gotToEvent(event))}
                  socialMedia={eventList.socialMedia}
                />
              ))}
            {modalState.show && modalState.event ? (
              <RegistrationModal
                event={modalState.event}
                eventListId={eventList.id}
                onHide={() => setModalState({ event: null, show: false })}
                show={modalState.show}
              />
            ) : null}
          </Page.Content>
        </>
      ) : null}
    </div>
  )
}

export default EventList
