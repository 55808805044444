// prettier-ignore
import { setProductLists, setSelectedProduct, setSelectedProductListIndex } from '../../../redux/actions/productListsActions'
import { Button, Form } from 'tabler-react'
import { CardMode, Service } from '../../../enums'
import { Col, Row } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSm, useSelector } from '../../../hooks'
import API from '../../../api'
import Loader from '../../../components/loader'
import Page from '../../../layout/page'
import ProductCard from '../../../components/cards/productCard'
import ProductListModal from '../../../components/modals/productListModal'
import React, { ChangeEvent, useEffect, useState } from 'react'

const Products: React.FC<RouteComponentProps> = ({ history }) => {
  const services = useSelector(state => state.auth.user?.services)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState<'' | 'add' | 'edit' | false>('')
  const dispatch = useDispatch()
  const { productLists, selectedProductListIndex } = useSelector(state => state.productLists)
  const sm = useSm()

  const retreiveProductLists = async () => {
    setLoading(true)

    const list = await API.ProductLists.getProductLists()

    if (list.length) {
      dispatch(setProductLists(list))
      selectedProductListIndex > list.length - 1 && dispatch(setSelectedProductListIndex(0))
    }

    setLoading(false)
  }

  const onAdd = async () => {
    history.push('/product')
  }

  const onEdit = async (productId: string) => {
    const product = productLists[selectedProductListIndex].products.find(product => product.id === productId)

    dispatch(setSelectedProduct(product ?? null))
    history.push('/product')
  }

  useEffect(() => {
    retreiveProductLists()
    // eslint-disable-next-line
  }, [])

  return loading ? (
    <Loader />
  ) : services && services.includes(Service.stripe) ? (
    <>
      <Page.Content title='Products'>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group>
              <Form.Select
                value={productLists[selectedProductListIndex] ? productLists[selectedProductListIndex].name : ''}
                onChange={(e: ChangeEvent<HTMLOptionsCollection>) => {
                  dispatch(setSelectedProductListIndex(e.target.selectedIndex))
                }}
              >
                {productLists.map((productList, index) => (
                  <option key={index}>{productList.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className={`mb-4 ${!sm ? 'text-center' : ''}`}>
            <Button type='button' icon='plus' color='primary' outline onClick={() => setShowModal('add')} />

            {productLists[selectedProductListIndex] ? (
              <>
                <Button
                  className='ml-2'
                  type='button'
                  icon='edit-2'
                  color='primary'
                  outline
                  onClick={() => setShowModal('edit')}
                />

                <Button
                  className='ml-2'
                  type='button'
                  icon='link'
                  color='primary'
                  onClick={() => history.push(`/products/${productLists[selectedProductListIndex].id}`)}
                  outline
                />

                <Button
                  className='ml-2'
                  type='button'
                  icon='clipboard'
                  color='primary'
                  outline
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_CLIENT_ADDRESS}/products/${productLists[selectedProductListIndex].id}`
                    )
                  }
                />
              </>
            ) : null}
          </Col>
        </Row>

        <Row>
          {productLists[selectedProductListIndex] &&
            productLists[selectedProductListIndex].products.map(product => (
              <Col key={product.id} xs={12}>
                <ProductCard
                  mode={CardMode.edit}
                  product={product}
                  onClick={() => onEdit(product.id)}
                  style={{ marginBottom: '10px' }}
                />
              </Col>
            ))}
        </Row>
        <Row>
          {productLists[selectedProductListIndex] ? (
            <Col className='text-center'>
              <Button className='mt-5' type='button' icon='plus' color='primary' outline onClick={onAdd}></Button>
            </Col>
          ) : null}
        </Row>
      </Page.Content>
      {showModal && (
        <ProductListModal
          productList={showModal === 'edit' ? productLists[selectedProductListIndex] : null}
          show={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  ) : (
    <Row className='justify-content-center'>
      <a
        style={{ position: 'absolute', top: '50%' }}
        href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_STRIPE_REDIRECT_URI}`}
      >
        <Button size='lg' color='primary'>
          Link Stripe
        </Button>
      </a>
    </Row>
  )
}

export default Products
