// prettier-ignore
import { addEvent, deleteEvent, resetRegistrations, setSelectedEvent, updateEvent } from '../../../redux/actions/eventListsActions'
import { Accordion } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ConfirmationModal from '../../../components/modals/confirmationModal'
import Page from '../../../layout/page'
import React, { useEffect, useState } from 'react'
import EventForm from '../../../components/forms/eventForm'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector } from '../../../hooks'
import { NewEvent } from '../../../types'

const Event: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading: confirmationModalLoading, selectedEvent } = useSelector(state => state.eventLists)
  const eventList = useSelector(state => state.eventLists.eventLists[state.eventLists.selectedEventListIndex])
  const [showResetModal, setShowResetModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setSelectedEvent(null))
    }
    // eslint-disable-next-line
  }, [])

  if (!eventList) {
    history.push('/')
    return null
  }

  const onDelete = async () => {
    selectedEvent && (await dispatch(deleteEvent(eventList.id, selectedEvent.id)))

    history.push('/')
  }

  const onSubmit = async (event: NewEvent) => {
    !selectedEvent && (await dispatch(addEvent(eventList.id, event)))
    selectedEvent && (await dispatch(updateEvent(eventList.id, { ...event, id: selectedEvent.id })))

    if (selectedEvent?.registrations?.length && selectedEvent.date !== event.date) setShowResetModal(true)
    else history.push('/')
  }

  return (
    <Page.Content title={selectedEvent ? selectedEvent.name : 'New event'}>
      {selectedEvent && selectedEvent.registrations?.length ? (
        <Accordion className='mb-5' defaultActiveKey='0'>
          <Accordion.Toggle className='form-label' as='span' eventKey='1'>
            Show registrations ▼
          </Accordion.Toggle>

          <Accordion.Collapse eventKey='1'>
            <div>
              {selectedEvent.registrations.map(registration => (
                <tr key={registration}>{registration}</tr>
              ))}
            </div>
          </Accordion.Collapse>
        </Accordion>
      ) : null}

      <EventForm event={selectedEvent} onDelete={onDelete} onSubmit={onSubmit} />

      <ConfirmationModal
        cancelButtonLabel='No'
        confirmButtonLabel='Yes'
        onConfirm={async () => {
          selectedEvent && (await dispatch(resetRegistrations(eventList.id, selectedEvent.id)))
          history.push('/')
        }}
        onCancel={() => history.push('/events')}
        title='Reset registrations?'
        message='You changed the date of the event, would you like to reset the registrations too?'
        loading={confirmationModalLoading}
        show={showResetModal}
      />
    </Page.Content>
  )
}

export default Event
