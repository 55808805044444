import { EventList } from '../../types'
import axios from 'axios'

const API = process.env.REACT_APP_API_ENDPOINT

export const createEventList = async (eventList: { name: string }): Promise<EventList | false> => {
  try {
    const { data } = await axios.post<{ eventList: EventList }>(`${API}/api/v1/eventLists`, eventList)

    return data.eventList
  } catch (error) {
    return false
  }
}

export const deleteEventList = async (eventListId: string): Promise<boolean> => {
  try {
    await axios.delete(`${API}/api/v1/eventLists/${eventListId}`)

    return true
  } catch (error) {
    return false
  }
}

export const getEventLists = async (): Promise<EventList[]> => {
  try {
    const { data } = await axios.get<{ eventLists: EventList[] }>(`${API}/api/v1/eventLists`)

    return data.eventLists
  } catch (error) {
    return []
  }
}

export const updateEventList = async (eventListId: string, eventList: EventList): Promise<EventList | false> => {
  try {
    const { data } = await axios.put<{ eventList: EventList }>(`${API}/api/v1/eventLists/${eventListId}`, eventList)

    return data.eventList
  } catch (error) {
    return false
  }
}
