import * as Auth from './auth/auth'
import * as Events from './events/events'
import * as EventLists from './eventLists/eventLists'
import * as Products from './products/products'
import * as ProductLists from './productLists/productLists'
import * as Stripe from './stripe/stripe'
import * as User from './user/user'
import * as Zoom from './zoom/zoom'

const API = {
  Auth,
  Events,
  EventLists,
  Products,
  ProductLists,
  Stripe,
  User,
  Zoom
}

export default API
