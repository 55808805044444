import React from 'react'

import PageContent from './PageContent'
import PageHeader from './PageHeader'
import PageMain from './PageMain'
import PageMapHeader from './PageMapHeader'
import PageSubTitle from './PageSubTitle'
import PageTitle from './PageTitle'

type Props = {
  children?: React.ReactNode
  className?: string
}

type PageType = React.FC<Props> & {
  Content: typeof PageContent
  Header: typeof PageHeader
  Main: typeof PageMain
  MapHeader: typeof PageMapHeader
  SubTitle: typeof PageSubTitle
  Title: typeof PageTitle
}

const Page: PageType = ({ children, className }) => <div className={`page ${className}`}>{children}</div>

Page.Content = PageContent
Page.Header = PageHeader
Page.Main = PageMain
Page.MapHeader = PageMapHeader
Page.SubTitle = PageSubTitle
Page.Title = PageTitle

export default Page
