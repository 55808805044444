import { Button, Form } from 'tabler-react'
import { Col, Row } from 'react-bootstrap'
import { CardMode } from '../../../enums'
import { RouteComponentProps } from 'react-router-dom'
import { setEventLists, setSelectedEvent, setSelectedEventListIndex } from '../../../redux/actions/eventListsActions'
import { sortByDate } from '../../../utils/arrays'
import { useDispatch } from 'react-redux'
import { useSm, useSelector } from '../../../hooks'
import API from '../../../api'
import EventCard from '../../../components/cards/eventCard'
import EventListModal from '../../../components/modals/eventListModal'
import Loader from '../../../components/loader'
import Page from '../../../layout/page'
import React, { ChangeEvent, useEffect, useState } from 'react'

const Home: React.FC<RouteComponentProps> = ({ history }) => {
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState<'' | 'add' | 'edit' | false>('')
  const dispatch = useDispatch()
  const { eventLists, selectedEventListIndex } = useSelector(state => state.eventLists)
  const socialMedia = useSelector(state => state.auth.user?.socialMedia)
  const sm = useSm()

  const retreiveEventLists = async () => {
    setLoading(true)

    const list = await API.EventLists.getEventLists()

    if (list.length) {
      dispatch(setEventLists(list))
      selectedEventListIndex > list.length - 1 && dispatch(setSelectedEventListIndex(0))
    }

    setLoading(false)
  }

  const onAdd = async () => {
    history.push('/event')
  }

  const onEdit = async (eventId: string) => {
    const event = eventLists[selectedEventListIndex].events.find(event => event.id === eventId)

    dispatch(setSelectedEvent(event ?? null))
    history.push('/event')
  }

  useEffect(() => {
    retreiveEventLists()
    // eslint-disable-next-line
  }, [])

  return loading ? (
    <Loader />
  ) : (
    <>
      <Page.Content title='Home'>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group>
              <Form.Select
                value={eventLists[selectedEventListIndex] ? eventLists[selectedEventListIndex].name : ''}
                onChange={(e: ChangeEvent<HTMLOptionsCollection>) => {
                  dispatch(setSelectedEventListIndex(e.target.selectedIndex))
                }}
              >
                {eventLists.map((eventList, index) => (
                  <option key={index}>{eventList.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className={`mb-4 ${!sm ? 'text-center' : ''}`}>
            <Button type='button' icon='plus' color='primary' outline onClick={() => setShowModal('add')} />

            {eventLists[selectedEventListIndex] ? (
              <>
                <Button
                  className='ml-2'
                  type='button'
                  icon='edit-2'
                  color='primary'
                  outline
                  onClick={() => setShowModal('edit')}
                />

                <Button
                  className='ml-2'
                  type='button'
                  icon='link'
                  color='primary'
                  onClick={() => history.push(`/events/${eventLists[selectedEventListIndex].id}`)}
                  outline
                />

                <Button
                  className='ml-2'
                  type='button'
                  icon='clipboard'
                  color='primary'
                  outline
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_CLIENT_ADDRESS}/events/${eventLists[selectedEventListIndex].id}`
                    )
                  }
                />
              </>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col>
            {eventLists[selectedEventListIndex] &&
              eventLists[selectedEventListIndex].events
                .sort(sortByDate)
                .map(event => (
                  <EventCard
                    key={event.id}
                    mode={CardMode.edit}
                    event={event}
                    onClick={() => onEdit(event.id)}
                    socialMedia={socialMedia}
                  />
                ))}
          </Col>
        </Row>
        <Row>
          {eventLists[selectedEventListIndex] ? (
            <Col className='text-center'>
              <Button className='mt-5' type='button' icon='plus' color='primary' outline onClick={onAdd}></Button>
            </Col>
          ) : null}
        </Row>
      </Page.Content>
      {showModal && (
        <EventListModal
          eventList={showModal === 'edit' ? eventLists[selectedEventListIndex] : null}
          show={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  )
}

export default Home
