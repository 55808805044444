export enum ModalsActionTypes {
  HIDE_MODAL = 'HIDE_MODAL',
  SHOW_MODAL = 'SHOW_MODAL',
  TOGGLE_MODAL = 'TOGGLE_MODAL'
}

type HideModal = {
  type: ModalsActionTypes.HIDE_MODAL
}

type ShowModal = {
  type: ModalsActionTypes.SHOW_MODAL
  modalType: string
  modalProps?: Record<string, unknown>
}

type ToggleModal = {
  type: ModalsActionTypes.TOGGLE_MODAL
}

export type ModalsAction = HideModal | ShowModal | ToggleModal
